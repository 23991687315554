import * as React from 'react';
import ImageColumGrid from '../components/common/ImageColumGrid';
import NewPlanRace from '../components/plans/NewPlanRace';
import useRestricted from '../hooks/useRestricted';
import { observer } from 'mobx-react-lite';
import NewPlanRaceRequestConfirmation from '../components/plans/NewPlanRaceRequestConfirmation';
import { ControlPoint, Plan } from '../services/PlansService';
import { AppContext } from '../contexts/AppContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NewPlanDivideOwn from '../components/plans/NewPlanDivideOwn';
import NewPlanOwn from '../components/plans/NewPlanOwn';
import Snackbar from '@mui/material/Snackbar';
import dayjs from 'dayjs';

export type NewPlanState = "type" | "race" | "add_race_confirmation" | "own" | "divide" | "finish";

const NewPlan: React.FC = observer(() => {

  useRestricted();

  const { userService, plansService, firebaseService } = React.useContext(AppContext);

  const navigate = useNavigate();


  const [state, setState] = React.useState<NewPlanState>("own");
  const [plan, setPlan] = React.useState<Partial<Plan>>({});
  const [isSavingPlan, setIsSavingPlan] = React.useState<NewPlanState>();
  const [error, setError] = React.useState(false);
  const [gpxFile, setGpxFile] = React.useState<File>();
  const [loading, setLoading] = React.useState(true);

  const [initialControlPoints, setInitialControlPoints] = React.useState<ControlPoint[]>([]);
  const [searchParams] = useSearchParams();

  const route = decodeURI(searchParams.get("route") || "");
  const controlPoints = searchParams.getAll("control-point").map((cp) => decodeURI(cp));
  const date = decodeURI(searchParams.get("date") || "");
  const name = decodeURI(searchParams.get("name") || "");
  const sport = decodeURI(searchParams.get("sport") || "");

  React.useEffect(() => {

    const startPlanWithParams = async (route: string, controlPoints: string[], date: string, name: string) => {

      setLoading(true);
      const dateConverted = dayjs(date).toDate();
      const planId = `${name.toLowerCase().replaceAll(" ", "-")}-${dateConverted.getTime()}`;
      const gpxFile = await plansService.loadGpxFileWithGpxName(`usersFiles/${route}`, userService.user?.id || "", planId);

      if (gpxFile) {

        const newControlPoints: ControlPoint[] = [];

        for (const cp of controlPoints) {
          const tempCP = cp.split(":");
          const cpDataString = tempCP.pop();
          const cpName = tempCP.join(":");

          const cpData = cpDataString?.split(",");
          if (cpData && cpData.length) {
            const [distance, food, drinks, wc, crew, dropbag] = cpData;
            const parsedDistance = parseFloat(distance);
            if (!isNaN(parsedDistance) && !newControlPoints.find((cp) => cp.distance === parsedDistance)) {
              newControlPoints.push({ name: cpName, distance: parseFloat(distance), type: "control", food: food === "1", drinks: drinks === "1", wc: wc === "1", crew: crew === "1", dropbag: dropbag === "1" });
            }
          }
        };

        setGpxFile(gpxFile);
        setInitialControlPoints(newControlPoints);
        setPlan(
          {
            name,
            date: dayjs(date).toDate(),
            gpxFile: {
              name: gpxFile.name,
              date: dateConverted,
              aidStations: [],
            },
            sport: sport === "bike" ? "bike" : "run",
          }
        );
        setState("divide");
      }

      setLoading(false);
    }

    if (userService.user?.id && route && controlPoints.length && date && name && state === "own") {
      startPlanWithParams(route, controlPoints, date, name);
    } else {
      setLoading(false);
    }
  }, [route, controlPoints, date, name, state, sport, userService.user?.id, plansService]);




  const handleStateChange = async (newState: NewPlanState) => {
    if (!userService.user?.id) {
      return;
    }

    else if (newState === "race") {
      setGpxFile(undefined);
      setPlanData({ gpxFile: undefined, distance: undefined, elevationGain: undefined, type: "race", sport: "run" });
    }

    else if (newState === "own") {
      setPlanData({ raceId: "", gpxFile: undefined, distance: undefined, elevationGain: undefined, type: "own", sport: "run" });
    }

    else if (newState === "finish") {

      const now = new Date();
      const newPlan = {
        ...plan,
        userId: userService.user.id,
        id: `${plan.name?.toLowerCase().replaceAll(" ", "-")}-${now.getTime()}`, createdAt: now, modifiedAt: now, createdBy: userService.user?.id || "", plannedEffort: [],
      } as Plan;
      setPlan(newPlan);

      setIsSavingPlan("finish");
      try {
        if (gpxFile) {
          await plansService.addGpxFile(`usersFiles/${userService.user.id}`, gpxFile, `${userService.user.id}-${newPlan.id}`);
        }
        await plansService.upsertPlan(userService.user.id, newPlan as Plan);
        await userService.updateUserData({ ...userService.user, newUser: false });
        navigate(`/plans/${userService.user.id}/${newPlan.id}`);
      } catch (error) {
        console.error(error);
        firebaseService.onEvent("exception", { description: error, userId: userService.user?.id });
        setError(true);
      }
      setIsSavingPlan(undefined);
    }

    setState(newState);
  }

  const setPlanData = (newPlanData: Partial<Plan>) => {
    setPlan({ ...plan, ...newPlanData });
  }

  const handleSetGpxFile = (file: File) => {
    setGpxFile(file);
  }

  return state !== "divide" ? (
    <ImageColumGrid
      image={plan.sport === "bike" ? `${process.env.PUBLIC_URL}/images/auth-background-bike.jpg` : `${process.env.PUBLIC_URL}/images/auth-background.jpg`}
    >
      {state === "race" && <NewPlanRace onStateChange={handleStateChange} setPlanData={setPlanData} plan={plan} />}
      {state === "add_race_confirmation" && <NewPlanRaceRequestConfirmation />}
      {state === "own" && <NewPlanOwn loading={loading} onStateChange={handleStateChange} setPlanData={setPlanData} plan={plan} setGpxFile={handleSetGpxFile} />}

      <Snackbar open={error} autoHideDuration={5000} onClose={() => setError(false)} message="There was an error saving the plan. Please try again" />
    </ImageColumGrid>
  ) : <NewPlanDivideOwn onStateChange={handleStateChange} setPlanData={setPlanData} plan={plan} userGpxFile={gpxFile} loading={isSavingPlan} initialControlPoints={initialControlPoints} />
});

export default NewPlan;
