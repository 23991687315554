import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Copyright from './Copyright';
import { ArrowBack } from '@mui/icons-material';
import { AppContext } from '../../contexts/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { useIntl } from 'react-intl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const SignUp: React.FC = () => {

    const navigate = useNavigate();
    const intl = useIntl();

    const { userService } = React.useContext(AppContext);

    const location = useLocation();

    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (!data.get('name') || !data.get('email') || !data.get('password') || !data.get('repeatPassword')) {
            setError("error.allFieldsRequired");
            setLoading(false);
            return;
        }
        if (data.get('email')?.toString().includes("+")) {
            setError("auth/invalid-email");
            setLoading(false);
            return;
        }
        const password = data.get('password')?.toString();
        if (password && password.length < 6) {
            setError("auth/invalid-password");
            setLoading(false);
            return;
        }
        if (data.get('password') !== data.get('repeatPassword')) {
            setError("error.passwordMissmatch");
            setLoading(false);
            return;
        }
        if (!data.get('acceptTerms')) {
            setError("error.acceptTerms");
            setLoading(false);
            return;
        }
        try {
            await userService.signUp(
                data.get('email') as string,
                data.get('password') as string,
                {
                    displayName: data.get('name') as string,
                    language: navigator.language.split(/[-_]/)[0] === "es" ? "es" : "en",
                    distanceUnits: navigator.language === "en-US" ? "miles" : "km",
                    elevationUnits: navigator.language === "en-US" ? "feet" : "m",
                    receiveEmails: data.get('receiveEmails') === "on",
                    newUser: true
                },
                location.state?.from || "/"
            );
        } catch (error) {
            if (error instanceof FirebaseError) {
                setError(error.code);
            } else {
                setError("error.unknown");
            }
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Button startIcon={<ArrowBack />} sx={{ alignSelf: "flex-start" }} onClick={() => navigate("/sign-in", { state: location.state })}>
                {intl.formatMessage({ id: "auth.backToSignIn" })}
            </Button>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {intl.formatMessage({ id: "auth.signUp" })}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSignUp} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label={intl.formatMessage({ id: "form.name" })}
                    name="name"
                    autoComplete="name"
                    autoFocus
                    onChange={() => setError(null)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={intl.formatMessage({ id: "form.email" })}
                    name="email"
                    autoComplete="email"
                    onChange={() => setError(null)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={intl.formatMessage({ id: "form.password" })}
                    type="password"
                    id="password"
                    onChange={() => setError(null)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="repeatPassword"
                    label={intl.formatMessage({ id: "form.passwordConfirm" })}
                    type="password"
                    id="repeatPassword"
                    onChange={() => setError(null)}
                />
                <FormControlLabel
                    control={<Checkbox name="acceptTerms" color="primary" />}
                    label={intl.formatMessage({ id: "form.acceptTerms" })}
                />
                <FormControlLabel
                    control={<Checkbox name="receiveEmails" color="primary" />}
                    label={intl.formatMessage({ id: "form.receiveEmails" })}
                />
                {
                    error && <Alert severity="error">{intl.formatMessage({ id: error, defaultMessage: intl.formatMessage({ id: "error.unknown" }) })}</Alert>
                }
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    loading={loading}
                >
                    {intl.formatMessage({ id: "auth.signUp" })}
                </LoadingButton>
                <Copyright sx={{ mt: 5 }} />
            </Box>
        </Box>
    );
};

export default SignUp;