import Box from "@mui/material/Box";
import { round } from "lodash";
import { SectionData } from "../../services/PlansService";
import { SurfaceData, getCountPerTechnicalityType, getCountPerSurface, getSurfaceColor, getTechnicalityColor } from "../../utils/getSurfaceData";
import { DonutPlotContainer } from "../common/DonutPlotContainer";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import CircularProgress from "@mui/material/CircularProgress";
import HelpIcon from '@mui/icons-material/Help';
import styled from "@mui/material/styles/styled";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

const HelpIconButton = styled(HelpIcon)(({ theme }) => ({
    cursor: "pointer",
    fontSize: "1rem",
}));

export interface SurfaceDataBoxProps {
    surfaceData?: SurfaceData[];
    sectionData: SectionData;
}

export const SurfaceDataBox: React.FC<SurfaceDataBoxProps> = ({ surfaceData, sectionData }) => {

    const intl = useIntl();

    const [surfaceDialogOpen, setSurfaceDialogOpen] = useState(false);
    const [technicalityDialogOpen, setTechnicalityDialogOpen] = useState(false);

    const surfaceDataOfSection = surfaceData?.filter(surface => (surface.distance || 0) >= sectionData.startDistance && (surface.distance || 0) <= sectionData.endDistance);
    const countPerTechnicality = getCountPerTechnicalityType(surfaceDataOfSection || []);
    const countPerSurface = getCountPerSurface(surfaceDataOfSection || []);

    return (
        <Box marginY={2}>
            {surfaceData?.length ?
                <>
                    <Box display="flex" alignItems="center" gap={1} marginBottom={1}>
                        <Typography variant="body2" fontWeight="bold">
                            {intl.formatMessage({ id: "data.surfaceTitle" })}
                        </Typography>
                        <HelpIconButton onClick={() => setSurfaceDialogOpen(true)} />
                    </Box>
                    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} marginBottom={2}>
                        <Box width="100%" height={75}>
                            <DonutPlotContainer
                                id="surface"
                                data={countPerSurface}
                                dimensions={{ width: 75, height: 75 }}
                            />
                        </Box>
                        <Box>
                            {
                                countPerSurface.map((surface) => (
                                    surface.value ?
                                        <Box key={surface.id} display="flex" alignItems="center" gap={1}>
                                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={surface.color}>
                                            </Box>
                                            <Typography variant="body2" fontSize={12}>
                                                {
                                                    intl.formatMessage({ id: `surface.${surface.id}` })
                                                } {round(surface.value * 100 / (surfaceDataOfSection?.length || 1), 1)}%
                                            </Typography>
                                        </Box> : null
                                ))
                            }
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1} marginBottom={1}>
                        <Typography variant="body2" fontWeight="bold">
                            {intl.formatMessage({ id: "data.technicalityTitle" })}
                        </Typography>
                        <HelpIconButton onClick={() => setTechnicalityDialogOpen(true)} />
                    </Box>
                    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                        <Box width="100%" height={75}>
                            <DonutPlotContainer
                                id="technicality"
                                data={countPerTechnicality}
                                dimensions={{ width: 75, height: 75 }}
                            />
                        </Box>
                        <Box>
                            {
                                countPerTechnicality.map((technicality) => (
                                    technicality.value ?
                                        <Box key={technicality.id} display="flex" alignItems="center" gap={1}>
                                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={technicality.color}>
                                            </Box>
                                            <Typography variant="body2" fontSize={12}>
                                                {
                                                    technicality.id === "-1" ? intl.formatMessage({ id: "technicality.unknown" }) :
                                                        `T${technicality.id}`
                                                } {round(technicality.value * 100 / (surfaceDataOfSection?.length || 1), 1)}%
                                            </Typography>
                                        </Box> : null
                                ))
                            }
                        </Box>
                    </Box>
                </> :
                <Box marginY={2} width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={2}>
                    <Typography variant="body2" color="textSecondary">
                        {intl.formatMessage({ id: "data.noSurfaceData" })}
                    </Typography>
                    <CircularProgress />
                </Box>
            }

            <Dialog
                open={surfaceDialogOpen}
                onClose={() => setSurfaceDialogOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    {intl.formatMessage({ id: "data.surfaceTitle" })}
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        {intl.formatMessage({ id: "data.surfaceDescription" })}
                    </Typography>
                    <Typography variant="body2">
                        {intl.formatMessage({ id: "data.surfaceDescription2" })}
                    </Typography>
                    <Link href="https://www.openstreetmap.org/" target="_blank" rel="noreferrer">
                        <Typography variant="body2" fontSize={12}>
                            © OpenStreetMap
                        </Typography>
                    </Link>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getSurfaceColor("paved")}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                {intl.formatMessage({ id: "surface.paved" })}
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "surface.pavedDescription" })}
                        </Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getSurfaceColor("cobblestone")}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                {intl.formatMessage({ id: "surface.cobblestone" })}
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "surface.cobblestoneDescription" })}
                        </Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getSurfaceColor("compacted")}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                {intl.formatMessage({ id: "surface.compacted" })}
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "surface.compactedDescription" })}
                        </Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getSurfaceColor("unpaved")}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                {intl.formatMessage({ id: "surface.unpaved" })}
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "surface.unpavedDescription" })}
                        </Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getSurfaceColor("alpine")}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                {intl.formatMessage({ id: "surface.alpine" })}
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "surface.alpineDescription" })}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSurfaceDialogOpen(false)}>
                        {intl.formatMessage({ id: "action.ok" })}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={technicalityDialogOpen}
                onClose={() => setTechnicalityDialogOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    {intl.formatMessage({ id: "data.technicalityTitle" })}
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2">
                        {intl.formatMessage({ id: "data.technicalityDescription" })}
                    </Typography>
                    <Typography variant="body2">
                        {intl.formatMessage({ id: "data.technicalityDescription2" })}
                    </Typography>
                    <Link href="https://www.openstreetmap.org/" target="_blank" rel="noreferrer">
                        <Typography variant="body2" fontSize={12}>
                            © OpenStreetMap
                        </Typography>
                    </Link>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getTechnicalityColor(0)}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                T0
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "technicality.0Description" })}
                        </Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getTechnicalityColor(1)}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                T1
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "technicality.1Description" })}
                        </Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getTechnicalityColor(2)}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                T2
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "technicality.2Description" })}
                        </Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getTechnicalityColor(3)}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                T3
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "technicality.3Description" })}
                        </Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getTechnicalityColor(4)}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                T4
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "technicality.4Description" })}
                        </Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getTechnicalityColor(5)}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                T5
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "technicality.5Description" })}
                        </Typography>
                    </Box>
                    <Box marginTop={2}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Box display="flex" alignItems="center" justifyContent="center" width={10} height={10} bgcolor={getTechnicalityColor(6)}></Box>
                            <Typography variant="body2" fontWeight="bold">
                                T6
                            </Typography>
                        </Box>
                        <Typography variant="body2">
                            {intl.formatMessage({ id: "technicality.6Description" })}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTechnicalityDialogOpen(false)}>
                        {intl.formatMessage({ id: "action.ok" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};