import * as React from 'react';
import useRestricted from '../hooks/useRestricted';
import { observer } from 'mobx-react-lite';
import { AppContext } from '../contexts/AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import TempoPlanner from '../components/plans/TempoPlanner';
import { PlotContainer } from '../components/common/PlotContainer';
import { Map } from '../components/plans/Map';
import { getLatLngForDistance } from '../utils/UnitsUtils';
import { StyleControl } from '../components/plans/StyleControl';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from '@mui/material/styles/styled';
import { Permission } from '../services/UserService';
import { TerrainControl } from '../components/plans/TerrainControl';
import Typography from '@mui/material/Typography';

const PlanPanel = styled(Box)(({ theme }) => ({
  borderRight: `solid 1px ${theme.palette.divider}`,
  width: 800,
  [theme.breakpoints.down('md')]: {
    width: "100%",
  }
}));

const Planner: React.FC = observer(() => {

  const permission = useRestricted();
  const intl = useIntl();

  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'));

  const { userService, plansService, mapService } = React.useContext(AppContext);

  const { userId, planId } = useParams();

  const navigate = useNavigate();

  const [openMobileAlert, setOpenMobileAlert] = React.useState<boolean>(!isUpMD);
  const [openShareAlert, setOpenShareAlert] = React.useState<boolean>(false);
  const [showCopiedAlert, setShowCopiedAlert] = React.useState<boolean>(false);

  const [error, setError] = React.useState<string | null>(null);

  const [currentSection, setCurrentSection] = React.useState<number>(0);

  const plan = plansService.plan;

  React.useEffect(() => {
    if (!userService.user?.id || !planId || !userId || (userService.user?.id !== userId)) {
      if (!userService.isLoadingUser) {
        navigate("/plans");
      }
      return;
    }

    if (plan && plan.id === planId) {
      return;
    }

    plansService.loadPlan(userId, planId).then((plan) => {
      if (plan) {
        plansService.parseCurrentPlanGpxFile().then((sucess) => {
          if (!sucess) {
            setError("error.gpxFileError");
            navigate("/plans");
          }
        });
      } else {
        navigate("/plans");
      }
    });
  }, [userService.isLoadingUser, plansService, planId, userId, userService.user?.id, navigate, plan]);

  React.useEffect(() => {
    if (plansService.bbox && isUpMD && permission !== Permission.loading) {
      mapService.init(plansService.bbox, permission);
    }
  }, [plansService.bbox, mapService, isUpMD, permission]);

  const handleGraphHover = (distance: number | null) => {
    if (!distance) {
      return;
    }
    const [lng, lat] = getLatLngForDistance(distance, plansService.latlng, plansService.distancePoints)
    mapService.setPointPostion(lat, lng);
  }

  const getShareUrl = () => {
    if (!userService.user?.id || !plan?.id) {
      return "";
    }

    const controlPoints = [...plan.controlPoints];
    controlPoints.pop();
    controlPoints.shift();
    return encodeURI(`${window.location.origin}/plans/new?route=${userService.user?.id}/${userService.user?.id}-${planId}${controlPoints.map((cp) => `&control-point=${cp.name}:${cp.distance},${cp.food ? "1" : "0"},${cp.drinks ? "1" : "0"},${cp.wc ? "1" : "0"},${cp.crew ? "1" : "0"},${cp.dropbag ? "1" : "0"}`).join("")}&date=${plan.date}&name=${plan.name}&sport=${plan.sport || "run"}`);
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setShowCopiedAlert(true);
  }

  return (
    !!(plan && plansService.sectionsData.length > 0 && plansService.latlng.length > 0) ? (
      <Box display="flex" height={isUpMD ? "100vh" : "auto"} overflow="hidden">
        <PlanPanel>
          <TempoPlanner
            plan={plan}
            setPlanData={plansService.setPlanData}
            currentSection={currentSection}
            changeSection={setCurrentSection}
            sectionsData={plansService.sectionsData}
            startLatlng={plansService.latlng[0]}
            endLatlng={plansService.latlng[plansService.latlng.length - 1]}
            plot={
              <Box paddingX={1} zIndex={10} position="relative">
                <PlotContainer
                  id="section-plot"
                  data={plansService.elevationSeries.filter(e => e.distance <= plansService.sectionsData[currentSection].endDistance && e.distance >= plansService.sectionsData[currentSection].startDistance)}
                  dimensions={{ width: 500, height: 200, margin: { top: 20, right: 0, bottom: 30, left: 40 } }}
                  breaks={[]}
                  setHoveredDistance={handleGraphHover}
                  selectionBoxBottom={true}
                  surfaceData={plansService.plan.surfaceData?.filter(e => e.distance && e.distance <= plansService.sectionsData[currentSection].endDistance && e.distance >= plansService.sectionsData[currentSection].startDistance)}
                />
              </Box>
            }
            surfaceData={plansService.plan.surfaceData}
            onShare={() => setOpenShareAlert(true)}
          />
        </PlanPanel>
        {isUpMD && <Box display="flex" flexDirection="column" width="100%">

          <Box flexGrow={1} position="relative">
            <Map />
            <StyleControl />
            <TerrainControl />
          </Box>
          <Box padding={1} zIndex={10}>
            <PlotContainer
              id="full-plot"
              data={plansService.elevationSeries}
              dimensions={{ width: 500, height: 300, margin: { top: 20, right: 0, bottom: 30, left: 40 } }}
              breaks={plansService.plan.controlPoints ? plansService.plan.controlPoints.slice(1, plansService.plan.controlPoints.length - 1).map((v) => v.distance * 1000) : []}
              setHoveredDistance={handleGraphHover}
              surfaceData={plansService.plan.surfaceData}
            />
          </Box>
        </Box>}

        <Snackbar
          open={plansService.isSavingPlan}
          message={intl.formatMessage({ id: "data.saving" })}
          ContentProps={{
            sx: {
              background: "#ffffff",
              color: "#000000"
            }
          }}
        />

        <Snackbar
          open={plansService.hasSaveError}
          message={intl.formatMessage({ id: "data.savingError" })}
          autoHideDuration={6000}
          ContentProps={{
            sx: {
              background: "#e51f1f"
            }
          }}
        />

        <Snackbar
          open={showCopiedAlert}
          message={intl.formatMessage({ id: "plans.copied" })}
          autoHideDuration={3000}
          color="success"
          onClose={() => setShowCopiedAlert(false)}
        />

        <Dialog
          open={openMobileAlert}
          onClose={() => setOpenMobileAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {intl.formatMessage({ id: "plans.mobileAlertTitle" })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {intl.formatMessage({ id: "plans.mobileAlertText" })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenMobileAlert(false)}>
              {intl.formatMessage({ id: "action.ok" })}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openShareAlert}
          onClose={() => setOpenShareAlert(false)}
          aria-labelledby="share-alert-dialog-title"
          aria-describedby="share-alert-dialog-description"
          maxWidth="md"
        >
          <DialogTitle id="share-alert-dialog-title">
            {intl.formatMessage({ id: "plans.shareTitle" })}
          </DialogTitle>
          <DialogContent>
            <Box paddingX={1} zIndex={10}>
              <PlotContainer
                id="full-plot-dialog"
                data={plansService.elevationSeries}
                dimensions={{ width: 500, height: 300, margin: { top: 20, right: 0, bottom: 30, left: 40 } }}
                breaks={plansService.plan.controlPoints ? plansService.plan.controlPoints.slice(1, plansService.plan.controlPoints.length - 1).map((v) => v.distance * 1000) : []}
                setHoveredDistance={handleGraphHover}
              />
            </Box>
            <DialogContent>
              <DialogContentText>{intl.formatMessage({ id: "plans.shareText" })}</DialogContentText>
              <Box marginTop={1} padding={1} borderRadius={1} onClick={() => copyToClipboard(getShareUrl())} style={{ cursor: "pointer", backgroundColor: "#eeeeee" }}>
                <Typography component="p" variant="subtitle2" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{getShareUrl()}</Typography>
                <Box display="flex" justifyContent="center" marginTop={1}>
                  <Typography variant="caption" textAlign="center">*{intl.formatMessage({ id: "plans.clickToCopy" })}</Typography>
                </Box>
              </Box>
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenShareAlert(false)}>
              {intl.formatMessage({ id: "action.close" })}
            </Button>
            <Button onClick={() => {
              copyToClipboard(getShareUrl());
              setOpenShareAlert(false);
            }}>
              {intl.formatMessage({ id: "action.closeAndCopy" })}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    ) : (<Box height="100vh" width="100%" display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>)
  );
});

export default Planner;
