import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { minutesToHHMM } from "../../utils/minutesToHHMM";
import { useContext, useEffect, useState } from "react";
import { SectionData } from "../../services/PlansService";
import { toArrivalTime } from "../../utils/toArrivalTime";
import { convertDistance, getBikePace, getPace } from "../../utils/UnitsUtils";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CheckOutlined from "@mui/icons-material/CheckOutlined";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import WarningOutlined from "@mui/icons-material/WarningOutlined";
import styled from "@mui/material/styles/styled";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { AppContext } from "../../contexts/AppContext";

const ParagraphLine = styled("span")(({ theme }) => ({
    verticalAlign: "baseline",
    height: 1,
    flexGrow: 1,
    flexShrink: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginLeft: "0.5em",
    marginRight: "0.5em"
}));

const SmallTextField = styled(TextField)(({ theme }) => ({
    maxWidth: theme.spacing(6),
    "& input": {
        fontSize: "0.875rem"
    }
}));

const PlannedTimeBox = styled(Box)(({ theme }) => ({
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(0),
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const CuttoffTimeBox = styled(Box)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

export interface TimeBoxProps {
    section: SectionData;
    hours?: number;
    minutes?: number;
    onChangeHours: (hours: number) => void;
    onChangeMinutes: (minutes: number) => void;
    onChangeCutoff: (cutOff: Date | null) => void;
    startDate: Date;
    totalTimeBefore: number;
    totalTime: number;
    totalDistance: number;
    sport: "run" | "bike";
}

const TimeBox: React.FC<TimeBoxProps> = observer(({ section, hours, minutes, onChangeHours, onChangeMinutes, onChangeCutoff, startDate, totalTime, totalTimeBefore, totalDistance, sport }) => {

    const { userService } = useContext(AppContext);
    const intl = useIntl();

    const [hoursStr, setHours] = useState<string>(hours ? hours.toString() : "0");
    const [minutesStr, setMinutes] = useState<string>(minutes ? minutes.toString() : "0");
    const [cutoff, setCutoff] = useState<dayjs.Dayjs | null>(section.cutOff ? dayjs(section.cutOff) : null);

    const distanceUnits = userService.user?.distanceUnits || "km";

    useEffect(() => {
        setCutoff(section.cutOff ? dayjs(section.cutOff) : null);
    }, [section]);

    useEffect(() => {
        setHours(hours ? hours.toString() : "0");
        setMinutes(minutes ? minutes.toString() : "0");
    }, [hours, minutes]);

    const onChangeHoursStr = (hours: string) => {
        if (hours === "") {
            setHours("");
            onChangeHours(0);
        } else {
            const hoursNum = parseInt(hours);
            if (!isNaN(hoursNum) && hoursNum >= 0) {
                setHours(hours);
                onChangeHours(hoursNum);
            }
        }
    };

    const onChangeMinutesStr = (minutes: string) => {
        if (minutes === "") {
            setMinutes("");
            onChangeMinutes(0);
        } else {
            const minutesNum = parseInt(minutes);
            if (!isNaN(minutesNum) && minutesNum >= 0 && minutesNum < 60) {
                setMinutes(minutes);
                onChangeMinutes(minutesNum);
            }
        }
    }

    const sectionEndTime = totalTimeBefore + (hours || 0) * 60 + (minutes || 0);

    const timeToCuttoff = cutoff ? Math.round(cutoff.diff(dayjs(startDate).add(sectionEndTime, 'minute'), "minute")) : undefined;

    return (
        <Box p={1}>
            {section.type !== "start" && section.type !== "end" && (
                <PlannedTimeBox display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.plannedTimeForSectionTitle" })}</Typography>
                    <Box display="flex" alignItems="baseline">
                        <SmallTextField hiddenLabel variant="standard" size="small" type="number" value={hoursStr} onChange={(ev) => onChangeHoursStr(ev.target.value)} autoFocus /><Typography variant="body2" marginRight={1}>h:</Typography>
                        <SmallTextField hiddenLabel variant="standard" size="small" type="number" value={minutesStr} onChange={(ev) => onChangeMinutesStr(ev.target.value)} /><Typography variant="body2">min</Typography>
                    </Box>
                </PlannedTimeBox>
            )}
            {
                section.type === "control" && (
                    <CuttoffTimeBox>
                        <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                            <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.cutoff" })}</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    value={cutoff}
                                    onChange={(newValue) => {
                                        setCutoff(newValue);
                                        onChangeCutoff(newValue?.toDate() || null)
                                    }
                                    }
                                    slotProps={
                                        {
                                            textField: {
                                                variant: 'standard',
                                                size: 'small',
                                                sx: {
                                                    "& input": {
                                                        fontSize: "0.875rem"
                                                    }
                                                }
                                            },
                                        }
                                    }
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                            <Typography variant="body2">{intl.formatMessage({ id: "data.timeToCuttoff" })}</Typography>
                            <ParagraphLine />
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography variant="body2" fontWeight="bold" color={
                                    timeToCuttoff && timeToCuttoff < 0 ? "error.main" : timeToCuttoff && timeToCuttoff > 10 ? "success.main" :
                                        ((timeToCuttoff && timeToCuttoff <= 10) ||
                                            timeToCuttoff === 0) ? "warning.main" : "inherit"
                                }>{
                                        timeToCuttoff === 0 ?
                                            `${timeToCuttoff}min` :
                                            timeToCuttoff && timeToCuttoff > 0 ?
                                                `+${timeToCuttoff}min` :
                                                timeToCuttoff && timeToCuttoff < 0 ?
                                                    `${timeToCuttoff}min` : ""
                                    }</Typography>

                                {timeToCuttoff && timeToCuttoff < 0 ? <ErrorOutline fontSize="small" color="error" /> : timeToCuttoff && timeToCuttoff > 10 ? <CheckOutlined fontSize="small" color="success" /> :
                                    ((timeToCuttoff && timeToCuttoff <= 10) ||
                                        timeToCuttoff === 0) ? <WarningOutlined fontSize="small" color="warning" /> : null}
                            </Box>
                        </Box>
                    </CuttoffTimeBox>
                )
            }
            {section.type === "section" && (
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: sport === "bike" ? "data.averageSpeedForSection" : "data.averagePaceForSection" })}</Typography>
                    <ParagraphLine />
                    {
                        sport === "bike" ?
                            <Typography variant="body2" fontWeight="bold">{getBikePace(convertDistance(section.distance / 1000, distanceUnits), (hours || 0) * 60 + (minutes || 0))} {distanceUnits === "km" ? "km" : "miles"}/h</Typography> :
                            <Typography variant="body2" fontWeight="bold">{getPace(convertDistance(section.distance / 1000, distanceUnits), (hours || 0) * 60 + (minutes || 0))} min/{distanceUnits === "km" ? "km" : "mile"}</Typography>
                    }

                </Box>
            )}
            {section.type !== "start" && section.type !== "end" && (
                <>
                    <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: sport === "bike" ? "data.averageSpeedForSectionEnd" : "data.averagePaceForSectionEnd" })}</Typography>
                        <ParagraphLine />
                        {
                            sport === "bike" ?
                                <Typography variant="body2" fontWeight="bold">{getBikePace(convertDistance(section.endDistance / 1000, distanceUnits), sectionEndTime)} {distanceUnits === "km" ? "km" : "miles"}/h</Typography> :
                                <Typography variant="body2" fontWeight="bold">{getPace(convertDistance(section.endDistance / 1000, distanceUnits), sectionEndTime)} min/{distanceUnits === "km" ? "km" : "mile"}</Typography>
                        }
                    </Box>
                    <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.finishTimeForSection" })}</Typography>
                        <ParagraphLine />
                        <Typography variant="body2" fontWeight="bold">{toArrivalTime(startDate, sectionEndTime)}</Typography>
                    </Box>
                </>
            )}
            <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                <Typography variant="body2">{intl.formatMessage({ id: "data.totalPlannedTime" })}</Typography>
                <ParagraphLine />
                <Typography variant="body2" fontWeight="bold">{minutesToHHMM(totalTime)}</Typography>
            </Box>
            <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                <Typography variant="body2">{intl.formatMessage({ id: sport === "bike" ? "data.totalAverageSpeed" : "data.totalAveragePace" })}</Typography>
                <ParagraphLine />
                {
                    sport === "bike" ?
                        <Typography variant="body2" fontWeight="bold">{getBikePace(convertDistance(totalDistance, distanceUnits), totalTime)} {distanceUnits === "km" ? "km" : "miles"}/h</Typography> :
                        <Typography variant="body2" fontWeight="bold">{getPace(convertDistance(totalDistance, distanceUnits), totalTime)} min/{distanceUnits === "km" ? "km" : "mile"}</Typography>
                }
            </Box>
            <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                <Typography variant="body2">{intl.formatMessage({ id: "data.totalFinishTime" })}</Typography>
                <ParagraphLine />
                <Typography variant="body2" fontWeight="bold">{toArrivalTime(startDate, totalTime)}</Typography>
            </Box>
            {section.type === "start" && (
                <Alert severity="info">{intl.formatMessage({ id: "data.timeboxInfo" })}</Alert>
            )}
        </Box>
    );
});

export default TimeBox;