import { observer } from "mobx-react-lite";
import useRestricted from "../hooks/useRestricted";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AppContext } from "../contexts/AppContext";
import { PlotContainer } from "../components/common/PlotContainer";
import { useIntl } from "react-intl";
import { round } from "lodash";
import { convertDistance, convertElevation, getPace, getBikePace } from "../utils/UnitsUtils";
import dayjs from "dayjs";
import { minutesToHHMM } from "../utils/minutesToHHMM";
import { toArrivalTime } from "../utils/toArrivalTime";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import CheckOutlined from "@mui/icons-material/CheckOutlined";
import WarningOutlined from "@mui/icons-material/WarningOutlined";
import OtherHouses from "@mui/icons-material/OtherHouses";
import DirectionsRun from "@mui/icons-material/DirectionsRun";
import Print from "@mui/icons-material/Print";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { getProductList, getTotalNutrition, getTotalSectionNutrition } from "../utils/NutritionUtils";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { StepIconProps } from "@mui/material/StepIcon/StepIcon";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Chip from "@mui/material/Chip";

const DocContainer = styled(Box)(({ theme }) => ({
  background: "rgb(204,204,204)",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  "@media print": {
    background: "white",
    margin: 0,
    boxShadow: "none",
    padding: 0
  }
}));

const Page = styled(Box)(({ theme }) => ({
  background: "white",
  display: "block",
  margin: "0 auto",
  marginBottom: "0.5cm",
  boxShadow: "0 0 0.5cm rgba(0,0,0,0.5)",
  width: "21cm",
  padding: "1cm",
  //height: "29.7cm",
  "@media print": {
    background: "white",
    margin: 0,
    boxShadow: "none",
    pageBreakBefore: "always"
  }
}));

const SmallTextCell = styled(TableCell)(({ theme }) => ({
  fontSize: "0.6rem",
  padding: theme.spacing(0.5),
}));

const SmallTextCellBold = styled(TableCell)(({ theme }) => ({
  fontSize: "0.6rem",
  padding: theme.spacing(0.5),
  fontWeight: "bold"
}));

const SmallTextCellFlex = styled(TableCell)(({ theme }) => ({
  fontSize: "0.6rem",
  padding: theme.spacing(0.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(0.5)
}));

const PrintButton = styled(Button)(({ theme }) => ({
  position: "fixed",
  top: theme.spacing(2),
  right: theme.spacing(2),
  "@media print": {
    display: "none"
  }
}));

const BackButton = styled(Button)(({ theme }) => ({
  position: "fixed",
  top: theme.spacing(2),
  left: theme.spacing(10),
  "@media print": {
    display: "none"
  }
}));

const cpIconStepComponent = (step: StepIconProps) => {
  return (
    <OtherHouses />
  );
};

const sectionIconStepComponent = (step: StepIconProps) => {
  return (
    <DirectionsRun />
  );
};

const Export: React.FC = observer(() => {

  useRestricted();

  const { userService, plansService, nutritionProductsService } = useContext(AppContext);

  const { userId, planId } = useParams();

  const navigate = useNavigate();

  const intl = useIntl();

  const plan = plansService.plan;

  const distanceUnits = userService.user?.distanceUnits || "km";
  const elevationUnits = userService.user?.elevationUnits || "m";

  useEffect(() => {
    if (!userService.user?.id || !planId || !userId || (userService.user?.id !== userId) || !nutritionProductsService) {
      if (!userService.isLoadingUser) {
        navigate("/plans");
      }
      return;
    }

    nutritionProductsService.loadNutritionProducts(userService.user.id).then(() => { });

    if (plan && plan.id === planId) {
      return;
    }

    plansService.loadPlan(userId, planId).then((plan) => {
      if (plan) {
        plansService.parseCurrentPlanGpxFile().then((sucess) => {
          if (!sucess) {
            navigate("/plans");
          }
        });
      } else {
        navigate("/plans");
      }
    });
  }, [userService.isLoadingUser, plansService, planId, userId, userService.user?.id, navigate, plan, nutritionProductsService]);

  if (!plan) {
    return <Box height="100vh" width="100%" display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>;
  }

  const sections = plansService.sectionsData.filter(s => s.type === "section");
  const totalTime = plan.plannedEffort.reduce((acc, effort) => acc + effort?.time || 0, 0);
  const totalHours = Math.floor(totalTime / 60);
  const totalMinutes = Math.floor(totalTime) % 60;

  const productList = getProductList(plan.plannedNutrition, nutritionProductsService.nutritionProducts);
  const totalNutritionValues = getTotalNutrition(plan.plannedNutrition, nutritionProductsService.nutritionProducts);

  const totalNutritionValuesPerHour = {
    water: totalTime ? totalNutritionValues.water / (totalTime / 60) : 0,
    calories: totalTime ? totalNutritionValues.calories / (totalTime / 60) : 0,
    carbs: totalTime ? totalNutritionValues.carbs / (totalTime / 60) : 0,
    protein: totalTime ? totalNutritionValues.protein / (totalTime / 60) : 0,
    fat: totalTime ? totalNutritionValues.fat / (totalTime / 60) : 0,
    sodium: totalTime ? totalNutritionValues.sodium / (totalTime / 60) : 0,
    caffeine: totalTime ? totalNutritionValues.caffeine / (totalTime / 60) : 0
  }

  return (
    <DocContainer>
      <Page>
        <Typography variant="h4" marginY={1}>{plan.name}</Typography>
        <Box marginTop={1} marginBottom={1} paddingX={2} display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "data.distanceTitle" })}</Typography>
            <Typography variant="body2">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(plan.distance, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "data.elevationGainTitle" })}</Typography>
            <Typography variant="body2">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(plan.elevationGain, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "data.elevationLossTitle" })}</Typography>
            <Typography variant="body2">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(plan.elevationLoss, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "data.dateTitle" })}</Typography>
            <Typography variant="body2">{dayjs(plan.date).locale(intl.locale).format("lll")}</Typography>
          </Box>
        </Box>
        <Box padding={1} zIndex={10} maxWidth={1200} maxHeight={400}>
          <PlotContainer
            id="full-plot"
            data={plansService.elevationSeries}
            dimensions={{ width: 1200, height: 200, margin: { top: 20, right: 0, bottom: 30, left: 40 } }}
            breaks={plansService.plan.controlPoints ? plansService.plan.controlPoints.slice(1, plansService.plan.controlPoints.length - 1).map((v) => v.distance * 1000) : []}
            setHoveredDistance={() => { }}
            insta={true}
          />
        </Box>

        <Typography variant="h5" marginY={1}>{intl.formatMessage({ id: "export.timeTable" })}</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <SmallTextCell align="center">{intl.formatMessage({ id: "export.section" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "export.distance" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "export.elevationGainLoss" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "export.expectedDuration" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "export.expectedTime" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: plan.sport === "bike" ? "export.averageSpeed" : "export.averagePace" })}</SmallTextCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sections.map((sectionData, index) => {

                const beforeTime = plan.plannedEffort.reduce((acc, cur, i) => i < index * 2 + 1 ? acc + cur.time : acc, 0);
                const sectionTime = plan.plannedEffort.length > index * 2 + 1 ? plan.plannedEffort[index * 2 + 1].time : 0;
                const pcTime = index * 2 + 2 < plan.plannedEffort.length - 1 ? plan.plannedEffort[index * 2 + 2].time : 0;

                const hours = Math.floor((sectionTime + pcTime) / 60);
                const minutes = Math.floor(sectionTime + pcTime) % 60;

                const timeToCuttoff = plansService.sectionsData[index * 2 + 2].cutOff ? Math.round(dayjs(plansService.sectionsData[index * 2 + 2].cutOff).diff(dayjs(plan.date).add(beforeTime + sectionTime + pcTime, 'minute'), "minute")) : undefined;

                return (
                  <TableRow
                    key={index}
                  >
                    <SmallTextCell align="center">
                      {convertDistance(round(sectionData.startDistance / 1000, 1), distanceUnits)} → {intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.endDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}
                    </SmallTextCell>
                    <SmallTextCell align="center">
                      {intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.distance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}
                    </SmallTextCell>
                    <SmallTextCell align="center">
                      +{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.elevationGain || 0, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })} / {intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.elevationLoss || 0, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}
                    </SmallTextCell>
                    <SmallTextCell align="center">
                      {minutesToHHMM(sectionTime)} {(index < sections.length - 1) && `(${minutesToHHMM(pcTime)})`}
                    </SmallTextCell>
                    <SmallTextCellFlex align="center">
                      {toArrivalTime(plan.date, beforeTime + sectionTime + pcTime)}
                      {!!timeToCuttoff && <Box display="flex" alignItems="center">
                        <Typography variant="body2" fontSize="0.6rem" color={
                          timeToCuttoff && timeToCuttoff < 0 ? "error.main" : timeToCuttoff && timeToCuttoff > 10 ? "success.main" :
                            ((timeToCuttoff && timeToCuttoff <= 10) ||
                              timeToCuttoff === 0) ? "warning.main" : "inherit"
                        }>({
                            timeToCuttoff === 0 ?
                              `${timeToCuttoff}min` :
                              timeToCuttoff && timeToCuttoff > 0 ?
                                `+${timeToCuttoff}min` :
                                timeToCuttoff && timeToCuttoff < 0 ?
                                  `${timeToCuttoff}min` : ""
                          }&nbsp;</Typography>

                        {timeToCuttoff && timeToCuttoff < 0 ? <ErrorOutline sx={{ fontSize: "0.6rem" }} color="error" /> : timeToCuttoff && timeToCuttoff > 10 ? <CheckOutlined sx={{ fontSize: "0.6rem" }} color="success" /> :
                          ((timeToCuttoff && timeToCuttoff <= 10) ||
                            timeToCuttoff === 0) ? <WarningOutlined sx={{ fontSize: "0.6rem" }} color="warning" /> : null}

                        <Typography variant="body2" fontSize="0.6rem" color={
                          timeToCuttoff && timeToCuttoff < 0 ? "error.main" : timeToCuttoff && timeToCuttoff > 10 ? "success.main" :
                            ((timeToCuttoff && timeToCuttoff <= 10) ||
                              timeToCuttoff === 0) ? "warning.main" : "inherit"
                        }>)</Typography>
                      </Box>}
                    </SmallTextCellFlex>
                    {
                      plan.sport === "bike" ?
                        <SmallTextCell align="center">{getBikePace(convertDistance(sectionData.distance / 1000, distanceUnits), (hours || 0) * 60 + (minutes || 0))} {distanceUnits === "km" ? "km" : "miles"}/h</SmallTextCell> :
                        <SmallTextCell align="center">{getPace(convertDistance(sectionData.distance / 1000, distanceUnits), (hours || 0) * 60 + (minutes || 0))} min/{distanceUnits === "km" ? "km" : "mile"}</SmallTextCell>
                    }
                  </TableRow>
                )
              })}

              <TableRow
                sx={{ border: 0 }}
              >
                <SmallTextCellBold align="center">
                  {intl.formatMessage({ id: "export.total" })}
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(plan.distance, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  +{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(plan.elevationGain || 0, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })} / {intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(plan.elevationLoss || 0, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {minutesToHHMM(totalTime)}
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {toArrivalTime(plan.date, totalTime)}
                </SmallTextCellBold>
                {
                  plan.sport === "bike" ?
                    <SmallTextCellBold align="center">{getBikePace(convertDistance(plan.distance, distanceUnits), (totalHours || 0) * 60 + (totalMinutes || 0))} {distanceUnits === "km" ? "km" : "miles"}/h</SmallTextCellBold> :
                    <SmallTextCellBold align="center">{getPace(convertDistance(plan.distance, distanceUnits), (totalHours || 0) * 60 + (totalMinutes || 0))} min/{distanceUnits === "km" ? "km" : "mile"}</SmallTextCellBold>
                }
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h5" marginBottom={1} marginTop={4} sx={{
          "@media print": {
            pageBreakBefore: "always"
          }
        }}>
          {intl.formatMessage({ id: "export.nutritionTable" })}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <SmallTextCell align="center">{intl.formatMessage({ id: "export.cpSection" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "export.products" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "data.waterTitle" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "data.caloriesTitle" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "data.carbsTitle" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "data.proteinTitle" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "data.fatTitle" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "data.sodiumTitle" })}</SmallTextCell>
                <SmallTextCell align="center">{intl.formatMessage({ id: "data.caffeineTitle" })}</SmallTextCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {plansService.sectionsData.map((sectionData, index) => {

                if (sectionData.type === "end") {
                  return null;
                }
                const sectionNutritionValues = getTotalSectionNutrition(index < plan.plannedNutrition.length ? plan.plannedNutrition[index].products || [] : [], nutritionProductsService.nutritionProducts);

                return (
                  <TableRow
                    key={index}
                  >
                    <SmallTextCell align="center">
                      {
                        sectionData.name ? sectionData.name :
                          sectionData.type === "start" ? intl.formatMessage({ id: "plans.start" }) :
                            sectionData.type === "control" ? `${intl.formatMessage({ id: "plans.CP" })} ${Math.ceil(index / 2)}` :
                              <>{convertDistance(round(sectionData.startDistance / 1000, 1), distanceUnits)} → {intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.endDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</>
                      }
                    </SmallTextCell>
                    <SmallTextCell align="center">
                      {plan.plannedNutrition[index]?.products?.filter(p => p.productId !== "water").map((product, index) => (
                        <Box key={index}>
                          {product.quantity && `${product.quantity} x`} {
                            nutritionProductsService.nutritionProducts.find(p => p.id === product.productId)?.name
                          }
                        </Box>
                      ))}
                    </SmallTextCell>
                    <SmallTextCell align="center">
                      {round(sectionNutritionValues.water, 2)} L
                    </SmallTextCell>
                    <SmallTextCell align="center">
                      {round(sectionNutritionValues.calories, 2)} kcal
                    </SmallTextCell>
                    <SmallTextCell align="center">
                      {round(sectionNutritionValues.carbs, 2)} g
                    </SmallTextCell>
                    <SmallTextCell align="center">
                      {round(sectionNutritionValues.protein, 2)} g
                    </SmallTextCell>
                    <SmallTextCell align="center">
                      {round(sectionNutritionValues.fat, 2)} g
                    </SmallTextCell>
                    <SmallTextCell align="center">
                      {round(sectionNutritionValues.sodium, 2)} mg
                    </SmallTextCell>
                    <SmallTextCell align="center">
                      {round(sectionNutritionValues.caffeine, 2)} mg
                    </SmallTextCell>
                  </TableRow>
                )
              })}

              <TableRow>
                <SmallTextCellBold align="center">
                  {intl.formatMessage({ id: "export.total" })}
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {productList.map((product, index) => (
                    <Box key={index}>
                      {product.quantity && `${product.quantity} x`} {
                        product.name
                      }
                    </Box>
                  ))}
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValues.water, 2)} L
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValues.calories, 2)} kcal
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValues.carbs, 2)} g
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValues.protein, 2)} g
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValues.fat, 2)} g
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValues.sodium, 2)} mg
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValues.caffeine, 2)} mg
                </SmallTextCellBold>
              </TableRow>

              <TableRow>
                <SmallTextCellBold align="center">
                  {intl.formatMessage({ id: "data.totalNutritionPerHour" })}
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  ⟶
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValuesPerHour.water, 2)} L
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValuesPerHour.calories, 2)} kcal
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValuesPerHour.carbs, 2)} g
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValuesPerHour.protein, 2)} g
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValuesPerHour.fat, 2)} g
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValuesPerHour.sodium, 2)} mg
                </SmallTextCellBold>
                <SmallTextCellBold align="center">
                  {round(totalNutritionValuesPerHour.caffeine, 2)} mg
                </SmallTextCellBold>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>


        <Typography variant="h5" marginBottom={1} marginTop={4} sx={{
          "@media print": {
            pageBreakBefore: "always"
          }
        }}>
          {intl.formatMessage({ id: "export.cpsAndSections" })}
        </Typography>
        <Stepper orientation="vertical">
          {plansService.sectionsData.map((sectionData, index) => {

            const sectionTime = plan.plannedEffort.length > index ? plan.plannedEffort[index].time : 0;

            const hours = Math.floor((sectionTime) / 60);
            const minutes = Math.floor(sectionTime) % 60;

            return (<Step key={index} active>
              <StepLabel StepIconComponent={sectionData.type === "section" ? sectionIconStepComponent : cpIconStepComponent}>
                {
                  sectionData.name ? sectionData.name :
                    sectionData.type === "start" ? intl.formatMessage({ id: "plans.start" }) :
                      sectionData.type === "control" ? `${intl.formatMessage({ id: "plans.CP" })} ${Math.ceil(index / 2)}` :
                        <>{convertDistance(round(sectionData.startDistance / 1000, 1), distanceUnits)} → {intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.endDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</>
                }
              </StepLabel>
              <StepContent>
                <>
                  <Box>
                    {sectionData.food && <Chip sx={{ marginRight: 0.5 }} label={intl.formatMessage({ id: "data.foodLabel" })} />}
                    {sectionData.drinks && <Chip sx={{ marginRight: 0.5 }} label={intl.formatMessage({ id: "data.drinksLabel" })} />}
                    {sectionData.wc && <Chip sx={{ marginRight: 0.5 }} label={intl.formatMessage({ id: "data.wcLabel" })} />}
                    {sectionData.dropbag && <Chip sx={{ marginRight: 0.5 }} label={intl.formatMessage({ id: "data.dropbagLabel" })} />}
                    {sectionData.crew && <Chip sx={{ marginRight: 0.5 }} label={intl.formatMessage({ id: "data.crewLabel" })} />}

                    {sectionData.type === "control" && <Typography variant="body2" fontSize={12} fontWeight="700" marginTop={1}>{intl.formatMessage({ id: "export.expectedTimeSection" })}: {minutesToHHMM(sectionTime)}</Typography>}
                    {(sectionData.type !== "section" && !!plan.plannedNutrition[index]?.products && !!plan.plannedNutrition[index]?.products.length) && <Box paddingY={1}>
                      <Typography variant="body2" fontSize={12} fontWeight="700" marginBottom={1}>{intl.formatMessage({ id: "plans.nutritionTitle" })}:</Typography>
                      <Box display="flex" flexDirection="column" justifyContent="space-around">
                        {plan.plannedNutrition[index]?.products?.map((product, index) => {
                          if (!product.quantity) return null;
                          return (
                            <Typography variant="body2" fontSize={12} fontWeight="700" key={index}>
                              {
                                product.productId === "water" ? `${product.quantity} x ${intl.formatMessage({ id: "data.waterLiters" })}` :
                                  `${product.quantity} x`} {
                                nutritionProductsService.nutritionProducts.find(p => p.id === product.productId)?.name
                              }
                            </Typography>
                          )
                        })}
                      </Box>
                    </Box>}

                    {
                      sectionData.type === "section" &&
                      <Box display="flex" gap={2}>
                        <Box width="40%">
                          <PlotContainer
                            id="section-plot"
                            data={plansService.elevationSeries.filter(e => e.distance <= plansService.sectionsData[index].endDistance && e.distance >= plansService.sectionsData[index].startDistance)}
                            dimensions={{ width: 500, height: 200, margin: { top: 20, right: 0, bottom: 30, left: 40 } }}
                            breaks={[]}
                            setHoveredDistance={() => { }}
                            insta={true}
                          />
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="space-around" paddingY={1} width="30%">
                          <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "data.distanceTitle" })}: {intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.distance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                          <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "data.elevationGainTitle" })}: +{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.elevationGain || 0, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                          <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "data.elevationLossTitle" })}: {intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.elevationLoss || 0, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                          <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "export.expectedTimeSection" })}: {minutesToHHMM(sectionTime)}</Typography>
                          {
                            plan.sport === "bike" ?
                              <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "export.averageSpeed" })}: {getBikePace(convertDistance(sectionData.distance / 1000, distanceUnits), (hours || 0) * 60 + (minutes || 0))} {distanceUnits === "km" ? "km" : "miles"}/h</Typography> :
                              <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "export.averagePace" })}: {getPace(convertDistance(sectionData.distance / 1000, distanceUnits), (hours || 0) * 60 + (minutes || 0))} min/{distanceUnits === "km" ? "km" : "mile"}</Typography>
                          }
                        </Box>
                        {(!!plan.plannedNutrition[index]?.products && !!plan.plannedNutrition[index]?.products.length) &&
                          <Box paddingY={1} width="30%">
                            <Typography variant="body2" fontSize={12} fontWeight="700" marginBottom={1}>{intl.formatMessage({ id: "plans.nutritionTitle" })}:</Typography>
                            <Box display="flex" flexDirection="column" justifyContent="space-around">
                              {plan.plannedNutrition[index]?.products?.map((product, index) => {
                                if (!product.quantity) return null;
                                return (
                                  <Typography variant="body2" fontSize={12} fontWeight="700" key={index}>
                                    {
                                      product.productId === "water" ? `${product.quantity} x ${intl.formatMessage({ id: "data.waterLiters" })}` :
                                        `${product.quantity} x`} {
                                      nutritionProductsService.nutritionProducts.find(p => p.id === product.productId)?.name
                                    }
                                  </Typography>
                                )
                              })}
                            </Box>
                          </Box>}
                      </Box>
                    }
                  </Box>
                  {plansService.plan?.notes && plansService.plan?.notes.length > index && <Box>
                    {
                      !!plansService.plan?.notes[index] &&
                      <div dangerouslySetInnerHTML={{ __html: plansService.plan?.notes[index] }} />
                    }
                  </Box>}
                </>
              </StepContent>
            </Step>);
          }
          )}
        </Stepper>



      </Page>
      <BackButton startIcon={<ArrowBack />} variant="contained" onClick={() => navigate(`/plans/${userId}/${planId}`)}>{intl.formatMessage({ id: "export.back" })}</BackButton>
      <PrintButton startIcon={<Print />} variant="contained" onClick={() => window.print()}>{intl.formatMessage({ id: "export.print" })}</PrintButton>
    </DocContainer>
  );
});

export default Export;
