export async function getSurfaceData(latlng: number[][], distancePoints: number[]): Promise<SurfaceData[]> {

    const queries: { latlng: number[], distance: number }[] = [];

    const distanceOffset = distancePoints[distancePoints.length - 1] * 0.0025;
    let lastDistance = 0;

    for (let i = 0; i < distancePoints.length; i++) {
        if (i === 0 || distancePoints[i] - lastDistance > distanceOffset) {
            queries.push({ latlng: latlng[i], distance: distancePoints[i] });
            lastDistance = distancePoints[i];
        }
    }

    try {
        const rawResponse = await fetch('https://overpass-api.de/api/interpreter', {
            method: 'POST',
            headers: {
                'Accept': 'application/osm3s+xml',
                'Content-Type': 'application/osm3s+xml'
            },
            body: `[out:json];
      ${queries.map(({ latlng, distance }, index) => `
      way(around: 5, ${latlng[1]}, ${latlng[0]});
      convert node
        :: id=id(),
        ::=::,
        distance = ${distance};
        out tags;
  `).join("")}`
        });
        const content = await rawResponse.json();

        //reduce elements so all tags of same distance are in one object
        const elements: SurfaceData[] = content.elements.reduce((acc: Record<string, string | number>[], el: any) => {
            const index = acc.findIndex((a) => a.distance === el.tags.distance);
            if (index === -1) {
                acc.push({ ...el.tags });
            } else {
                acc[index] = { ...acc[index], ...el.tags };
            }
            return acc;
        }, []);

        return elements.map((tags) => { return { distance: tags.distance, ...processSurfaceData(tags) } });
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export type SurfaceData = {
    technicality: number;
    surface: string;
    distance?: number;
}

const sacScale = ["strolling", "hiking", "mountain_hiking", "demanding_mountain_hiking", "alpine_hiking", "demanding_alpine_hiking", "difficult_alpine_hiking"];
const smoothness0 = ["excellenge", "good", "intermediate", "bad", "very bad"];
const smoothness1 = ["horrible", "very_horrile"];

const surfacePaved = ["paved", "asphalt", "concrete", "metal", "wood", "concrete:plates", "concrete:lanes", "stone"];
const surfaceCobblestone = ["paving_stones", "sett", "cobblestone", "pebblestone", "grass_paver", "unhewn_cobblestone"];
const surfaceCompacted = ["compacted"];
const surfaceUnpaved = ["unpaved", "ground", "dirt", "earth", "grass", "gravel", "sand", "fine_gravel", "mud", "rock", "woodchips"];

const highwayUnpaved = ["path", "track", "bridleway"];
const highwayAlpine = ["via_ferrata", "climbing", "scree", "rock", "glacier", "snow", "shingle"];

const sacScaleAlpine = ["demanding_mountain_hiking", "alpine_hiking", "demanding_alpine_hiking", "difficult_alpine_hiking"];

const technicalityColors = ["#00C853", "#0288D1", "#FFD600", "#FF6D00", "#D50000", "#000000", "#0D0D0D"];

const processSurfaceData = (tags: Record<string, string | number>) => {

    let surface;

    if (!!tags.surface) {
        if (surfacePaved.includes(tags.surface.toString())) {
            surface = "paved";
        } else if (surfaceCobblestone.includes(tags.surface.toString())) {
            surface = "cobblestone";
        } else if (surfaceCompacted.includes(tags.surface.toString())) {
            surface = "compacted";
        } else if (surfaceUnpaved.includes(tags.surface.toString())) {
            surface = "unpaved";
        }
    }

    if (!surface && !!tags.highway) {
        if (highwayUnpaved.includes(tags.highway.toString())) {
            surface = "unpaved";
        } else if (highwayAlpine.includes(tags.highway.toString())) {
            surface = "alpine";
        } else {
            surface = "paved";
        }
    }

    if (!surface && !!tags.sac_scale) {
        if (sacScaleAlpine.includes(tags.sac_scale.toString())) {
            surface = "alpine";
        }
    }

    let technicality = -1;

    if (!!tags.sac_scale) {
        const sacScaleIndex = sacScale.indexOf(tags.sac_scale.toString());
        if (sacScaleIndex !== -1) {
            technicality = sacScaleIndex;
        }
    } else if (!!tags.mtb_scale) {
        if (!isNaN(+tags.mtb_scale)) {
            technicality = +tags.mtb_scale;
            if (technicality === 5) {
                technicality = 4;
            }
        }
    } else if (!!tags.smoothness) {
        if (smoothness0.includes(tags.smoothness.toString())) {
            technicality = 0;
        }
        if (smoothness1.includes(tags.smoothness.toString())) {
            technicality = 1;
        }
    } else if (surface === "paved" || surface === "cobblestone" || surface === "compacted") {
        technicality = 0;
    } else if (surface === "alpine") {
        technicality = 6;
    }

    return {
        technicality,
        surface: surface || "unknown"
    } as SurfaceData;
}

export const getTechnicalityColor = (technicality: number) => {
    if (technicality === -1) {
        return "#FAFAFA";
    }
    return technicalityColors[technicality];
}

export const getSurfaceColor = (surface: string) => {
    switch (surface) {
        case "paved":
            return "#8A939E";
        case "cobblestone":
            return "#8B857B";
        case "compacted":
            return "#CAC2B2";
        case "unpaved":
            return "#A89070";
        case "alpine":
            return "#B3B99D";
        default:
            return "#FAFAFA";
    }
}

export const getCountPerTechnicalityType = (surfaceData: SurfaceData[]) => {
    const technicalities = [0, 1, 2, 3, 4, 5, 6, -1];
    const technicalityCount = technicalities.map((technicality) => surfaceData.filter((data) => data.technicality === technicality).length);
    return technicalities.map((technicality, index) => { return { id: technicality.toString(), value: technicalityCount[index], color: getTechnicalityColor(technicality) } });
}

export const getCountPerSurface = (surfaceData: SurfaceData[]) => {
    const surfaces = ["paved", "cobblestone", "compacted", "unpaved", "alpine", "unknown"];
    const surfaceCount = surfaces.map((surface) => surfaceData.filter((data) => data.surface === surface).length);
    return surfaces.map((surface, index) => { return { id: surface, value: surfaceCount[index], color: getSurfaceColor(surface) } });
};