import React, { useEffect, useState } from "react";
import { Point } from "../../types/visualization";
import * as d3 from "d3";
import { convertDistance, convertElevation, getElevation, round } from "../../utils/UnitsUtils";
import { useIntl } from "react-intl";
import { AppContext } from "../../contexts/AppContext";
import Box from "@mui/material/Box";
import { getSurfaceColor, getTechnicalityColor, SurfaceData } from "../../utils/getSurfaceData";
import getGradeColor from "../../utils/getGradeColor";

function resizeTextRect(text: any, rect: any) {
  if (!text || !text.node()) return;
  const { x, y, width: w, height: h } = text.node()!.getBBox();
  rect.attr("x", x - 10).attr("y", y - 5).attr("width", w + 15).attr("height", h + 10);
}

export type PlotType = "steep" | "slope" | "standard";
export type PlotRange = "full" | "section";

export type PlotContainerProps = {
  id: string,
  data: Point[];
  surfaceData?: SurfaceData[];
  dimensions: { width: number; height: number; margin: { top: number; right: number; bottom: number; left: number } };
  breaks?: number[];
  setHoveredDistance?: (distance: number | null) => void;
  selectionBoxBottom?: boolean;
  insta?: boolean;
};

export const PlotContainer: React.FC<PlotContainerProps> = ({
  id,
  data: fullData,
  surfaceData,
  dimensions,
  breaks,
  setHoveredDistance,
  selectionBoxBottom,
  insta
}) => {

  const data = fullData.length > 5000 ? fullData.filter((_, index) => index % Math.floor(fullData.length / 5000) === 0) : fullData;

  const { userService } = React.useContext(AppContext);

  const distanceUnits = userService.user?.distanceUnits || "km";
  const elevationUnits = userService.user?.elevationUnits || "m";

  const intl = useIntl();

  const svgRef = React.useRef(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const { width: minWidth, height, margin } = dimensions;

  const marginBottom = margin.bottom + 20;

  const [width, setWidth] = useState(containerRef.current?.clientWidth || minWidth - margin.left - margin.right);

  useEffect(() => {
    setWidth(containerRef.current?.clientWidth || minWidth - margin.left - margin.right);
  }, []);

  // Declare the x (horizontal position) scale.
  const x = d3.scaleLinear().domain(d3.extent(data.map(d => d.distance)) as [number, number]).range([margin.left, width - margin.right]);

  const elevations = data.map(d => d.elevation);
  // Declare the y (vertical position) scale.
  const y = d3.scaleLinear().domain([d3.min(elevations), d3.max(elevations)] as [number, number]).range([height - marginBottom, margin.top]);

  const xTicks = x.ticks(width / 80);
  const yTicks = y.ticks(height / 40);

  const brush = d3.brushX<Point>().extent([[margin.left, margin.top], [width - margin.right, height - marginBottom]]);

  brush.on("brush end", ({ selection }) => {
    if (selection) {

      const selectionX = selection.map(x.invert, x);

      const selectionDistance = selectionX[1] - selectionX[0];

      const elevations = getElevation(data.filter(d => d.distance >= selectionX[0] && d.distance <= selectionX[1]).map(d => d.elevation));

      const isLessThanHalf = selection[0] < width / 2;
      const brushTextX = isLessThanHalf ? selection[0] : selection[1];
      const brushTextDX = isLessThanHalf ? "1.25em" : "-1.25em";
      const brushTextAnchor = isLessThanHalf ? 'start' : 'end';
      const brushTextY = selectionBoxBottom ? height + 15 : -margin.top;

      const brushRect = svg.selectAll('.brushRect')
        .attr('x', brushTextX)
        .attr('y', brushTextY)
        .attr('fill', '#FFFFFF')
        .attr('pointer-events', 'none')
        .attr('stroke', '#000000');

      const brushText = svg.selectAll('.brushText')
        .attr('fill', "#000000")
        .style('text-anchor', brushTextAnchor)
        .data([,])
        .join("text")
        .call(text => text
          .selectAll("tspan")
          .data([intl.formatMessage({ id: "data.distance" }, { value: convertDistance(round(selectionDistance / 1000, 2), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) }), intl.formatMessage({ id: "data.elevationGain" }, { value: convertElevation(round(elevations.elevationGain, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) }), intl.formatMessage({ id: "data.elevationLoss" }, { value: convertElevation(round(elevations.elevationLoss, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })])
          .join("tspan")
          .attr("x", brushTextX)
          .attr("y", brushTextY)
          .attr('dx', brushTextDX)
          .attr('dy', (_, i) => `${(i * 1.25)}em`)
          .text(d => d));

      resizeTextRect(brushText, brushRect)
    } else {
      svg.selectAll('.brushText').attr('fill', "none");
      svg.selectAll('.brushRect').attr('fill', "none").attr('stroke', "none");
    }
  });

  brush.on("start", ({ selection }) => {
    if (!selection) {
      svg.selectAll('.brushText').attr('fill', "none");
      svg.selectAll('.brushRect').attr('fill', "none").attr('stroke', "none");
    }
  });

  const svg = d3.select(svgRef.current);
  svg.select(".brush").call(brush as any);
  const bisectDistance = d3.bisector<Point, number>(d => d.distance).right;

  const mouseMove = (event: any) => {
    const mouseDistance = x.invert(d3.pointer(event)[0]);

    setHoveredDistance?.(mouseDistance);

    if (x(mouseDistance) < margin.left ||
      x(mouseDistance) > width - margin.right) {
      return;
    }

    const xIndex = bisectDistance(data, mouseDistance, 1);
    const mouseElevation = data.length > xIndex ? data[xIndex].elevation : 0;

    svg.selectAll('.hoverLine')
      .attr('x1', x(mouseDistance))
      .attr('y1', margin.top)
      .attr('x2', x(mouseDistance))
      .attr('y2', height - marginBottom)
      .attr('stroke', '#000000')
      .attr('fill', '#000000')
      .attr('pointer-events', 'none')
      ;

    svg.selectAll('.hoverPoint')
      .attr('cx', x(mouseDistance))
      .attr('cy', y(mouseElevation))
      .attr('r', '4')
      .attr('fill', '#000000')
      .attr('pointer-events', 'none')
      ;

    const hoverRect = svg.selectAll('.hoverRect')
      .attr('x', x(mouseDistance))
      .attr('y', margin.top)
      .attr('fill', '#FFFFFF')
      .attr('pointer-events', 'none')
      .attr('stroke', '#000000')
      ;

    const isLessThanHalf = xIndex > data.length / 2;
    const hoverTextX = isLessThanHalf ? '-1.25em' : '1.25em';
    const hoverTextAnchor = isLessThanHalf ? 'end' : 'start';
    const hoverTextY = y(mouseElevation) < height / 2 ? 1.25 : -1.25;

    if (xIndex >= 0 && xIndex < data.length) {

      let surfaceDataIndex = 0;
      if (surfaceData?.length) {
        surfaceDataIndex = surfaceData.findIndex(d => (d.distance || 0) >= data[xIndex].distance);
        surfaceDataIndex = surfaceDataIndex === -1 ? surfaceData.length - 1 : surfaceDataIndex;
        surfaceDataIndex = surfaceDataIndex === 0 ? 0 : surfaceDataIndex - 1;
      }

      const technicality = surfaceData ? surfaceData[surfaceDataIndex].technicality : 0;
      const surface = surfaceData ? surfaceData[surfaceDataIndex].surface : "unknown";

      const hoverText = svg.selectAll('.hoverText')
        .attr('fill', "#000000")
        .style('text-anchor', hoverTextAnchor)
        //.text(formatElevation(mouseElevation));
        .data([,])
        .join("text")
        .call(text => text
          .selectAll("tspan")
          .data([
            intl.formatMessage({ id: "data.grade" }, { value: round(data[xIndex].slope, 0) }),
            intl.formatMessage({ id: "data.elevation" }, { value: convertElevation(round(mouseElevation, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) }),
            intl.formatMessage({ id: "data.distance" }, { value: convertDistance(round(mouseDistance / 1000, 2), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) }),
            intl.formatMessage({ id: "data.surface" }, { value: intl.formatMessage({ id: `surface.${surface}` }) }),
            intl.formatMessage({ id: "data.technicality" }, { value: technicality >= 0 ? `${technicality}/6` : intl.formatMessage({ id: "technicality.unknown" }) }),
          ])
          .join("tspan")
          .attr("x", x(mouseDistance))
          .attr("y", y(mouseElevation))
          .attr('dx', hoverTextX)
          .attr('dy', (_, i) => `${(i + 1) * hoverTextY}em`)
          .text(d => d));

      resizeTextRect(hoverText, hoverRect);
      const tspansPosition = hoverText.selectAll("tspan").nodes().map((tspan) => (
        {
          x: (tspan as any).getBBox().x,
          y: (tspan as any).getBBox().y,
          height: (tspan as any).getBBox().height,
        }
      ));

      svg.selectAll('.gradeRect')
        .attr('fill', getGradeColor(data[xIndex].slope))
        .attr('x', tspansPosition[0].x - 7)
        .attr('y', tspansPosition[0].y + tspansPosition[0].height / 2 - 2)
        .attr('width', 4)
        .attr('height', 4)

      svg.selectAll('.technicalityRect')
        .attr('fill', getTechnicalityColor(technicality))
        .attr('x', tspansPosition[4].x - 7)
        .attr('y', tspansPosition[4].y + tspansPosition[4].height / 2 - 2)
        .attr('width', 4)
        .attr('height', 4)

      svg.selectAll('.surfaceRect')
        .attr('fill', getSurfaceColor(surface))
        .attr('x', tspansPosition[3].x - 7)
        .attr('y', tspansPosition[3].y + tspansPosition[3].height / 2 - 2)
        .attr('width', 4)
        .attr('height', 4)
    }


  };

  svg.on('mousemove', mouseMove);

  return (
    <Box ref={containerRef} width="100%">
      {data.length > 0 && <svg ref={svgRef} width={width} height={height} viewBox={`0, 0, ${width}, ${height}`}
        style={{
          maxWidth: "100%",
          font: "10px sans-serif",
          overflow: "visible",
        }}
      >
        <clipPath id={`clip-${id}`}>
          <rect width={width} height={height - marginBottom} />
        </clipPath>
        <path clipPath={`url(#clip-${id})`} fill="#44ce1b"
          d={
            d3.area<Point>()
              .x(function (d, i) { return x(d.distance) })
              .y0(function (d) { return y(0) })
              .y1(function (d, i) {
                if (
                  Math.abs(d.slope) < 3 ||
                  (i > 0 && Math.abs(data[i - 1].slope) < 3)
                ) { return y(d.elevation) } else { return y(0) }
              })(data) || ""
          }
        />
        <path clipPath={`url(#clip-${id})`} fill="#bbdb44"
          d={
            d3.area<Point>()
              .x(function (d, i) { return x(d.distance) })
              .y0(function (d) { return y(0) })
              .y1(function (d, i) {
                if (
                  (Math.abs(d.slope) < 7 && Math.abs(d.slope) >= 3) ||
                  (i > 0 && Math.abs(data[i - 1].slope) < 7 && Math.abs(data[i - 1].slope) >= 3)
                ) { return y(d.elevation) } else { return y(0) }
              })(data) || ""
          }
        />
        <path clipPath={`url(#clip-${id})`} fill="#f7e379"
          d={
            d3.area<Point>()
              .x(function (d, i) { return x(d.distance) })
              .y0(function (d) { return y(0) })
              .y1(function (d, i) {
                if (
                  (Math.abs(d.slope) < 16 && Math.abs(d.slope) >= 7) ||
                  (i > 0 && Math.abs(data[i - 1].slope) < 16 && Math.abs(data[i - 1].slope) >= 7)
                ) { return y(d.elevation) } else { return y(0) }
              })(data) || ""
          }
        />
        <path clipPath={`url(#clip-${id})`} fill="#f2a134"
          d={
            d3.area<Point>()
              .x(function (d, i) { return x(d.distance) })
              .y0(function (d) { return y(0) })
              .y1(function (d, i) {
                if (
                  (Math.abs(d.slope) < 25 && Math.abs(d.slope) >= 16) ||
                  (i > 0 && Math.abs(data[i - 1].slope) < 25 && Math.abs(data[i - 1].slope) >= 16)
                ) { return y(d.elevation) } else { return y(0) }
              })(data) || ""
          }
        />
        <path clipPath={`url(#clip-${id})`} fill="#e51f1f"
          d={
            d3.area<Point>()
              .x(function (d, i) { return x(d.distance) })
              .y0(function (d) { return y(0) })
              .y1(function (d) { if (Math.abs(d.slope) >= 25) { return y(d.elevation) } else { return y(0) } })
              .y1(function (d, i) {
                if (
                  (Math.abs(d.slope) >= 25) ||
                  (i > 0 && Math.abs(data[i - 1].slope) >= 25)
                ) { return y(d.elevation) } else { return y(0) }
              })(data) || ""
          }
        />
        <g transform={`translate(0,${height - marginBottom})`}>
          <line stroke="currentColor" transform={`translate(${x(data[0].distance)}, 0)`} x2={width - margin.left - margin.right} />
          {xTicks.map((xTick) => (
            <g
              key={xTick}
              transform={`translate(${x(xTick)}, 0)`}
            >
              <line
                y2="6"
                stroke="currentColor"
              />
              <text
                key={xTick}
                style={{
                  fontSize: "10px",
                  textAnchor: "middle",
                  transform: "translateY(20px)"
                }}>
                {intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(+xTick / 1000, 2), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}
              </text>
            </g>
          ))}
        </g>
        <g transform={`translate(${margin.left},0)`}>
          {
            yTicks.map((yTick) => (
              <g
                key={yTick}
                transform={`translate(0,${y(yTick)})`}
              >
                <line stroke="currentColor" x2={width - margin.left - margin.right} strokeOpacity="0.1" />
                <text
                  key={yTick}
                  style={{
                    fontSize: "10px",
                    textAnchor: "end",
                    transform: "translateY(3px)"
                  }}>
                  {intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(+yTick, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}
                </text>
              </g>
            ))
          }
        </g>
        {
          breaks && breaks.length > 0 && breaks.map((aidStation, i) =>
            <line
              key={i}
              x1={x(aidStation)}
              y1={margin.top}
              x2={x(aidStation)}
              y2={height - marginBottom}
              strokeDasharray="5,5"
              stroke="#000000"
              fill="#000000"
              pointerEvents="none"
            />
          )
        }
        <g className="brush" />
        <line className="hoverLine" />
        <circle className="hoverPoint" />
        <rect className="hoverRect" />
        <text className="hoverText" />
        <rect className="gradeRect" />
        <rect className="surfaceRect" />
        <rect className="technicalityRect" />

        {
          surfaceData && surfaceData.map((d, i) => (
            <rect
              key={i}
              x={x(d.distance || 0)}
              y={height - 25}
              width={i < surfaceData.length - 1 ? x(surfaceData[i + 1].distance || 0) - x(d.distance || 0) : x(data[data.length - 1].distance) - x(d.distance || 0)}
              height={10}
              fill={getSurfaceColor(d.surface)}
              stroke="none"
              pointerEvents="none"
            />
          ))
        }

        {
          surfaceData && surfaceData.map((d, i) => (
            <rect
              key={i}
              x={x(d.distance || 0)}
              y={height - 10}
              width={i < surfaceData.length - 1 ? x(surfaceData[i + 1].distance || 0) - x(d.distance || 0) : x(data[data.length - 1].distance) - x(d.distance || 0)}
              height={10}
              fill={getTechnicalityColor(d.technicality)}
              stroke="none"
              pointerEvents="none"
            />
          ))
        }

        <rect className="brushRect" />
        <text className="brushText" />
      </svg>
      }
    </Box>
  );
};
