import React, {  } from "react";
import * as d3 from "d3";
import Box from "@mui/material/Box";

export type DonutPlotType = {
  id: string;
  value: number;
  color: string;
};

export type DonutPlotContainerProps = {
  id: string,
  data: DonutPlotType[];
  dimensions: { width: number; height: number; };
};

export const DonutPlotContainer: React.FC<DonutPlotContainerProps> = ({
  id,
  data,
  dimensions,
}) => {


  const { width, height } = dimensions;

  const containerRef = React.useRef<HTMLDivElement>(null);

  const radius = width / 2;

  const arc = d3.arc<d3.PieArcDatum<DonutPlotType>>()
    .innerRadius(radius * 0.67)
    .outerRadius(radius - 1);

  const pie = d3.pie<DonutPlotType>()
    .padAngle(1 / radius)
    .sort(null)
    .value(d => d.value);

  return (
    <Box ref={containerRef} width="100%" display="flex" justifyContent="center">
      {data.length > 0 && <svg width={width} height={height} viewBox={`0, 0, ${width}, ${height}`}
        style={{
          font: "10px sans-serif",
          overflow: "visible",
        }}
      >
        <g transform={`translate(${width / 2}, ${height / 2})`}>
          {pie(data).map((d, i) => (
            <path key={i} d={arc(d) || ""} fill={d.data.color} />
          ))}
        </g>
      </svg>
      }
    </Box>
  );
};
