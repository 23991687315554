import { makeAutoObservable } from "mobx";
import FirebaseService from "./FirebaseService";
import { User, onAuthStateChanged, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { NavigateFunction } from "react-router-dom";

export enum Permission {
    loading,
    free,
    pro,
    admin,
}

export type UserData = {
    id?: string,
    createdAt: Date;
    displayName: string;
    email: string;
    permissions?: string[];
    language?: string;
    distanceUnits?: string;
    elevationUnits?: string;
    receiveEmails?: boolean;
    preferedSport?: "run" | "bike";
    tokens?: number;
    newUser?: boolean;
    proExpDate?: Date;
    subscriptionStatus?: string;
};

export type AdditionalInformation = {
    displayName?: string;
    language?: string;
    distanceUnits?: string;
    elevationUnits?: string;
    receiveEmails?: boolean;
    tokens?: number;
    newUser?: boolean;
};

export default class UserService {

    get user() {
        return this._user;
    }

    get isLoadingUser() {
        return this._isLoadingUser;
    }

    private _firebaseService: FirebaseService;
    private _navigate: NavigateFunction;

    private _user: UserData | null = null;

    private _isLoadingUser = true;

    private _returnUrl: string | null = null;

    constructor(firebaseService: FirebaseService, navigate: NavigateFunction) {

        this._firebaseService = firebaseService;
        this._navigate = navigate;
        makeAutoObservable(this, undefined, { autoBind: true, deep: false })
    }

    init() {
        const auth = this._firebaseService.auth;
        onAuthStateChanged(
            auth,
            async (userAuth) => {
                if (userAuth) {
                    const userSnapshot =
                        await this.createUserDocumentFromAuth(
                            userAuth,
                        );

                    if (userSnapshot) {
                        this._user = { ...this._user, ...userSnapshot };
                        if (this._returnUrl) {
                            this._navigate(this._returnUrl);
                            this._returnUrl = null;
                        }
                        this._isLoadingUser = false;
                    }
                } else {
                    this._isLoadingUser = false;
                }
            },
        );
    }

    async refreshUser() {
        if (!this._firebaseService.auth.currentUser) {
            return;
        }

        const userSnapshot =
            await this.createUserDocumentFromAuth(
                this._firebaseService.auth.currentUser,
            );

        if (userSnapshot) {
            this._user = { ...this._user, ...userSnapshot };
        }

        return this._user;
    }

    async signIn(email: string, password: string, returnUrl: string) {
        const auth = this._firebaseService.auth;
        this._returnUrl = returnUrl;
        return await signInWithEmailAndPassword(auth, email, password);
    }

    async signUp(email: string, password: string, additionalInformation: AdditionalInformation, returnUrl: string) {
        const auth = this._firebaseService.auth;
        this._returnUrl = returnUrl;
        const userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
        );
        if (userCredential) {
            const { user } = userCredential;
            await this.createUserDocumentFromAuth(
                user,
                additionalInformation
            );
        }
    }

    async signOutUser() {
        const auth = this._firebaseService.auth;
        this._user = null;
        await signOut(auth);
        this._navigate('/');
    }

    async updateUserData(userData: UserData) {

        if (!this._user?.id) {
            return;
        }

        const db = this._firebaseService.db;
        const userDocRef = doc(db, 'users', this._user.id);

        try {
            if (!userData.proExpDate) {
                delete userData.proExpDate;
            }
            await setDoc(userDocRef, userData);

            this._user = { ...this._user, ...userData };
        } catch (error) {
            console.log('error creating the user', error);
        }
    }

    async forgotPassword(email: string) {
        const auth = this._firebaseService.auth;
        return sendPasswordResetEmail(auth, email);
    }

    private async createUserDocumentFromAuth(
        userAuth: User,
        additionalInformation: AdditionalInformation = {}
    ): Promise<UserData> {

        const db = this._firebaseService.db;
        const userDocRef = doc(db, 'users', userAuth.uid);

        const userSnapshot = await getDoc(userDocRef);

        if (!userSnapshot.exists()) {
            const { displayName, email } = userAuth;
            const createdAt = new Date();

            try {
                await setDoc(userDocRef, {
                    id: userAuth.uid,
                    displayName,
                    email,
                    createdAt,
                    ...additionalInformation,
                });
                if ((additionalInformation.displayName) && email) {
                    this._user = { id: userAuth.uid, displayName: additionalInformation.displayName, email, createdAt, ...additionalInformation };
                }
            } catch (error) {
                console.log('error creating the user', error);
            }
        }

        return { ...userSnapshot.data(), proExpDate: userSnapshot.data()?.proExpDate?.toDate(), id: userSnapshot.id } as UserData;
    }
}