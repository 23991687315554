import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NewPlanState } from '../../pages/NewPlan';
import { AddOutlined, ArrowBack, WorkspacePremium } from '@mui/icons-material';
import { ControlPoint, Plan, Section } from '../../services/PlansService';
import { useIntl } from 'react-intl';
import { AppContext } from '../../contexts/AppContext';
import { Point } from '../../types/visualization';
import { convertDistance, convertDistanceMiles, getElevation, getSlopesAndElevationSeriesFromGeojsonFeature, round } from '../../utils/UnitsUtils';
import { PlotContainer } from '../common/PlotContainer';
import { latlngSVG } from '../../utils/latlngSVG';
import ControlPointsTable from './ControlPointsTable';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { gpx } from "@tmcw/togeojson";

export interface NewPlanDivideOwnTypeProps {
    onStateChange: (newState: NewPlanState) => void;
    setPlanData: (newPlanData: Partial<Plan>) => void;
    plan: Partial<Plan>;
    loading?: NewPlanState;
    userGpxFile?: File;
    initialControlPoints?: ControlPoint[];
}

const NewPlanDivideOwn: React.FC<NewPlanDivideOwnTypeProps> = ({ onStateChange, setPlanData, plan, loading, userGpxFile, initialControlPoints }) => {

    const intl = useIntl();

    const { racesService, userService } = React.useContext(AppContext);

    const [error, setError] = React.useState<string | null>(null);

    const [elevationSeries, setElevationSeries] = React.useState<Point[]>([]);
    const [distancePoints, setDistancePoints] = React.useState<number[]>([]);

    const [svg, setSvg] = React.useState("");

    const [editingAmount, setEditingAmount] = React.useState<number>(0);

    React.useEffect(() => {
        if (initialControlPoints && initialControlPoints.length && svg) {
            handleSetValues(initialControlPoints.map((cp) => ({ ...cp, distance: userService.user?.distanceUnits === "miles" ? convertDistance(cp.distance, "miles") : cp.distance })));
        }
    }, [initialControlPoints, svg]);

    const handleSetValues = (controlPoints: ControlPoint[]) => {

        const sections: Section[] = [];

        const newControlPoints: ControlPoint[] = [
            { distance: 0, name: "", food: false, drinks: false, wc: false, crew: false, dropbag: false, type: "start" },
            ...controlPoints.map((cp) => ({ ...cp, distance: userService.user?.distanceUnits === "miles" ? convertDistanceMiles(cp.distance, "km") : cp.distance })),
            { distance: distancePoints[distancePoints.length - 1] / 1000, name: "", food: false, drinks: false, wc: false, crew: false, dropbag: false, type: "end" }
        ];

        for (let i = 0; i < newControlPoints.length - 1; i++) {
            sections.push({ startDistance: newControlPoints[i].distance * 1000, endDistance: newControlPoints[i + 1].distance * 1000 });
        }

        if (!plan.raceId) {
            const elevations = getElevation(elevationSeries.map((p) => p.elevation));
            setPlanData({
                controlPoints: newControlPoints,
                sections, svg: svg || plan.svg,
                distance: round(distancePoints[distancePoints.length - 1] / 1000, 2), elevationGain: elevations.elevationGain, elevationLoss: elevations.elevationLoss
            });
        } else {
            setPlanData({ controlPoints: newControlPoints, sections, svg: svg || plan.svg });
        }
    };

    const handleFileChange = (file: File) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            const parser = new DOMParser();
            if (e?.target?.result) {
                const xmlDoc = parser.parseFromString(
                    e.target.result as string,
                    "text/xml"
                );
                const geoJson = gpx(xmlDoc);
                if (geoJson && geoJson.features && geoJson.features.length > 0) {
                    const { elevationSeries, distancePoints, altitudePoints, latlng } = await getSlopesAndElevationSeriesFromGeojsonFeature(geoJson.features[0]);

                    setSvg(latlngSVG(latlng, 50));
                    setElevationSeries(elevationSeries);
                    setDistancePoints(distancePoints);

                    if (!altitudePoints.find(a => !!a)) {
                        setError("error.altitudeError");
                    }
                }
            }
        };
        reader.readAsText(file);
    };

    React.useEffect(() => {
        if (plan.raceId && plan.gpxFile) {
            racesService.getGpxFile(plan.raceId, plan.gpxFile).then((gpxFile) => {
                if (gpxFile) {
                    handleFileChange(gpxFile);
                }
                else
                    setError("error.gpxFileError");
            });
        } else if (userGpxFile) {
            handleFileChange(userGpxFile);
        }
    }, [plan.gpxFile, plan.raceId, racesService, userGpxFile]);

    const onNext = () => {
        onStateChange("finish");
    }

    if (!elevationSeries.length || !distancePoints.length) {
        return <Box height="100vh" width="100%" display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>;
    }

    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
            }}
        >
            <Button startIcon={<ArrowBack />} sx={{ alignSelf: "flex-start" }} onClick={() => onStateChange(plan.raceId ? "race" : "own")}>
                {intl.formatMessage({ id: "nav.back" })}
            </Button>
            <Avatar sx={{ marginX: "auto", marginY: 1, bgcolor: 'secondary.main' }}>
                <AddOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
                {intl.formatMessage({ id: "plans.addYourSections" })}
            </Typography>
            <Box sx={{ mt: 1 }}>

                {!!elevationSeries && !!elevationSeries.length &&
                    <>
                        <PlotContainer
                            id="divide-own-plot"
                            data={elevationSeries}
                            dimensions={{ width: 500, height: 300, margin: { top: 10, right: 10, bottom: 30, left: 40 } }}
                            breaks={plan.controlPoints ? plan.controlPoints.slice(1, plan.controlPoints.length - 1).map((v) => v.distance * 1000) : []}
                        />
                        <Typography variant="body2" sx={{ my: 2 }}>
                            <strong>{intl.formatMessage({ id: "plans.sectionsExplanation" })}</strong>
                        </Typography>
                        <ControlPointsTable
                            initialValues={initialControlPoints ? initialControlPoints.map((cp) => ({ ...cp, distance: userService.user?.distanceUnits === "miles" ? convertDistance(cp.distance, "miles") : cp.distance })) : []}
                            maxDistance={userService.user?.distanceUnits === "miles" ? (convertDistance(distancePoints[distancePoints.length - 1] / 1000, "miles", false)) : (distancePoints[distancePoints.length - 1] / 1000)}
                            onUpdate={handleSetValues}
                            onEditingAmountChange={setEditingAmount}
                            editingAmount={editingAmount}
                        />
                    </>
                }


                {
                    error && <Alert severity="error" action={
                        error === "error.altitudeError" && <Button href="mailto:support@timeontrails.com" color="inherit" size="small">
                            {intl.formatMessage({ id: "payment.contactSupport" }, { numSections: 0 })}
                        </Button>
                    }>{intl.formatMessage({ id: error, defaultMessage: intl.formatMessage({ id: "error.unknown" }) })}</Alert>
                }
                <LoadingButton
                    loading={loading === "finish"}
                    fullWidth
                    variant="contained"
                    sx={{ my: 1 }}
                    disabled={!elevationSeries || !elevationSeries.length || loading === "finish" || !!error || editingAmount > 0 || !plan.controlPoints || plan.controlPoints.length < 3}
                    onClick={onNext}
                >
                    {intl.formatMessage({ id: "nav.finish" })}
                </LoadingButton>
            </Box>
        </Box >
    );
};

export default NewPlanDivideOwn;