export default function getGradeColor(grade: number): string {
    if (
        Math.abs(grade) < 3
    ) return "#44ce1b";

    if ((Math.abs(grade) < 7 && Math.abs(grade) >= 3))
        return "#bbdb44";

    if ((Math.abs(grade) < 16 && Math.abs(grade) >= 7))
        return "#f7e379";

    if (Math.abs(grade) < 25 && Math.abs(grade) >= 16)
        return "#f2a134";

    if (Math.abs(grade) >= 25)
        return "#e51f1f";

    return "#44ce1b";
}