import { makeAutoObservable } from "mobx";
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyCQF42toeqAKCz3nsTSloxXM0ZaQ-bKhPQ",
    authDomain: "trail-tools.firebaseapp.com",
    projectId: "trail-tools",
    storageBucket: "trail-tools.appspot.com",
    messagingSenderId: "444221127725",
    appId: "1:444221127725:web:53284eabe3068376a9cca4",
    measurementId: "G-FYK8ENNZK1"
};
export default class FirebaseService {

    private _firebaseApp = initializeApp(firebaseConfig);

    private _analytics = getAnalytics();

    private _auth = getAuth();

    private _db = getFirestore();

    private _storage = getStorage();

    get auth() {
        return this._auth;
    }

    get db() {
        return this._db;
    }

    get storage() {
        return this._storage;
    }

    get analytics() {
        return this._analytics;
    }

    constructor() {

        this._analytics.app.automaticDataCollectionEnabled = true;
        makeAutoObservable(this, undefined, { autoBind: true, deep: false })
    }

    onEvent(name: string, params?: Record<string, any>) {
        logEvent(this._analytics, name, params);
    }
}