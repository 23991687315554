import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridSlots,
    GridPreProcessEditCellProps,
} from '@mui/x-data-grid';
import {
    randomId,
} from '@mui/x-data-grid-generator';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';
import { ControlPoint } from '../../services/PlansService';
import Snackbar from '@mui/material/Snackbar';

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
    onAddControlPoint: () => void;
    disabled: boolean;
}

function EditToolbar(props: EditToolbarProps) {

    const intl = useIntl();
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
        const id = randomId();
        setRows((oldRows) => [...oldRows, { id, distance: undefined, name: '', food: false, drinks: false, wc: false, crew: false, dropbag: false, type: "control", isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'distance' },
        }));
        props.onAddControlPoint();
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} disabled={props.disabled}>
                {intl.formatMessage({ id: "action.addControlPoint" })}
            </Button>
        </GridToolbarContainer>
    );
}


const ControlPointsTable: React.FC<{ maxDistance: number, onUpdate: (controlPoints: ControlPoint[]) => void, onEditingAmountChange: (amount: number) => void, editingAmount: number, initialValues: ControlPoint[] }> = observer(({ maxDistance, onUpdate, onEditingAmountChange, editingAmount, initialValues }) => {

    const intl = useIntl();

    const [rows, setRows] = React.useState<GridRowsProp>([]);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {

        if (!initialValues.length || rows.length) {
            return;
        }

        const newRows = initialValues.map((cp) => ({ ...cp, id: randomId(), isNew: true }));
        setRows(newRows);
        onEditingAmountChange(newRows.length);
        setTimeout(() => {
            for (const row of newRows) {
                handleSaveClick(row.id)();
            }
        }, 10);
    }, [initialValues]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        } else {
            onEditingAmountChange(editingAmount - 1);
        }
    };

    const handleRowEditStart: GridEventListener<'rowEditStart'> = (params, event) => {
        if (editingAmount > 0) {
            event.defaultMuiPrevented = true;
        } else {
            onEditingAmountChange(editingAmount + 1);
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        for (const rowId in rowModesModel) {
            if (rowModesModel[rowId].mode === GridRowModes.Edit) {
                return;
            }
        }
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        onEditingAmountChange(editingAmount + 1);
    };

    const handleSaveClick = (id: GridRowId) => () => {

        if (error) {
            return;
        }
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        onEditingAmountChange(editingAmount - 1);
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        const newRows = rows.filter((row) => row.id !== id);
        setRows(newRows);
        onUpdate((newRows as ControlPoint[]).filter(r => !!r.distance));
    };

    const handleCancelClick = (id: GridRowId) => () => {

        setError(null);
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            const newRows = rows.filter((row) => row.id !== id);
            setRows(newRows);
            onUpdate((newRows as ControlPoint[]).filter(r => !!r.distance));
        }
        onEditingAmountChange(editingAmount - 1);
    };

    const processRowUpdate = (newRow: GridRowModel) => {

        if (!newRow.distance) {
            setTimeout(() => {
                handleDeleteClick(newRow.id)();
            }, 10);
            return newRow;
        }

        const updatedRow = { ...newRow, isNew: false };
        const newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row)).sort((a, b) => ((a as ControlPoint).distance || 0) - ((b as ControlPoint).distance || 0));
        setRows(newRows);
        onUpdate((newRows as ControlPoint[]).filter(r => !!r.distance));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [
        {
            field: 'distance',
            type: 'number',
            headerName: intl.formatMessage({ id: "data.distanceTitle" }),
            width: 100,
            editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = !params.props.value;
                setError(hasError ? "error.controlPointDistance" : null);

                if (params.props.value < 0 || params.props.value > maxDistance) {
                    setError("error.controlPointDistanceOutOfRange");
                }

                const duplicateRow = rows.find(r => r.distance === params.props.value);

                if (duplicateRow && duplicateRow.id !== params.id) {
                    setError("error.controlPointDistanceDuplicate");
                }

                return { ...params.props, error: hasError };
            },
            sortingOrder: ['desc'],
        },
        {
            field: 'name',
            headerName: intl.formatMessage({ id: "data.name" }),
            width: 180,
            editable: true,
            renderCell: (params) => {
                if (params.value === '') {
                    return intl.formatMessage({ id: "data.untitledControlPoint" });
                }
            }
        },
        {
            field: 'food',
            headerName: intl.formatMessage({ id: "data.food" }),
            type: 'boolean',
            editable: true,
            width: 150,
        },
        {
            field: 'drinks',
            headerName: intl.formatMessage({ id: "data.drinks" }),
            type: 'boolean',
            editable: true,
            width: 150,
        },
        {
            field: 'wc',
            headerName: intl.formatMessage({ id: "data.wc" }),
            type: 'boolean',
            editable: true,
            width: 150,
        },
        {
            field: 'dropbag',
            headerName: intl.formatMessage({ id: "data.dropbag" }),
            type: 'boolean',
            editable: true,
            width: 150,
        },
        {
            field: 'crew',
            headerName: intl.formatMessage({ id: "data.crew" }),
            type: 'boolean',
            editable: true,
            width: 150,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: intl.formatMessage({ id: "data.actions" }),
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label={intl.formatMessage({ id: "action.save" })}
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label={intl.formatMessage({ id: "action.cancel" })}
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label={intl.formatMessage({ id: "action.edit" })}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label={intl.formatMessage({ id: "action.delete" })}
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                autosizeOptions={{ expand: true }}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                onRowEditStart={handleRowEditStart}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar as GridSlots['toolbar'],
                }}
                slotProps={{
                    toolbar: {
                        setRows, setRowModesModel,
                        onAddControlPoint: () => {
                            onEditingAmountChange(editingAmount + 1);
                        },
                        disabled: editingAmount > 0,
                    },
                }}
                disableColumnSorting
            />
            <Snackbar
                open={!!error}
                message={intl.formatMessage({ id: error || "error.unknown" }, { max: Math.floor(maxDistance * 10) / 10 })}
                autoHideDuration={6000}
                ContentProps={{
                    sx: {
                        background: "#e51f1f"
                    }
                }}
            />
        </Box>
    );
});

export default ControlPointsTable;