import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { ControlPoint, SectionData } from "../../services/PlansService";
import { convertDistance, convertElevation, round } from "../../utils/UnitsUtils";
import { useContext, useEffect, useState } from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { AppContext } from "../../contexts/AppContext";
import Check from "@mui/icons-material/Check";
import Edit from "@mui/icons-material/Edit";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import { SurfaceData } from "../../utils/getSurfaceData";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NordicWalking from "@mui/icons-material/NordicWalking";
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { SurfaceDataBox } from "./SurfaceDataBox";

dayjs.extend(localizedFormat);

const ParagraphLine = styled("span")(({ theme }) => ({
    verticalAlign: "baseline",
    height: 1,
    flexGrow: 1,
    flexShrink: 1,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    marginLeft: "0.5em",
    marginRight: "0.5em"
}));

const ArrowLine = styled("span")(({ theme }) => ({
    verticalAlign: "center",
    height: 1,
    flexGrow: 1,
    flexShrink: 1,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    marginLeft: "0.5em",
}));

const ArrowPoint = styled("span")(({ theme }) => ({
    width: 0,
    height: 0,
    borderTop: "5px solid transparent",
    borderBottom: "5px solid transparent",
    borderLeft: `10px solid ${theme.palette.grey[400]}`,
    marginRight: "0.5em"
}));

const PlannerTab = styled(Tab)(({ theme }) => ({
    textTransform: "none",
    minHeight: 48,
    position: "relative",
}));

export interface SectionTableProps {
    currentSection: number;
    sectionData: SectionData;
    surfaceData?: SurfaceData[];
    onSaveControlPoint: (cpIndex: number, cpData: ControlPoint) => void;
}

const SectionTable: React.FC<SectionTableProps> = observer(({ currentSection, sectionData, surfaceData, onSaveControlPoint }) => {

    const intl = useIntl();

    const { userService } = useContext(AppContext);

    const distanceUnits = userService.user?.distanceUnits || "km";
    const elevationUnits = userService.user?.elevationUnits || "m";

    const [currentName, setCurrentName] = useState<string>(sectionData.name || "");
    const [currentFood, setCurrentFood] = useState<boolean>(sectionData.food || false);
    const [currentDrinks, setCurrentDrinks] = useState<boolean>(sectionData.drinks || false);
    const [currentWc, setCurrentWc] = useState<boolean>(sectionData.wc || false);
    const [currentDropbag, setCurrentDropbag] = useState<boolean>(sectionData.dropbag || false);
    const [currentCrew, setCurrentCrew] = useState<boolean>(sectionData.crew || false);

    const [sectionTableState, setSectionTableState] = useState<"technicality" | "beforeAfter">("technicality");
    const [editing, setEditing] = useState<boolean>(false);

    useEffect(() => {
        setCurrentName(sectionData.name || "");
        setCurrentFood(sectionData.food || false);
        setCurrentDrinks(sectionData.drinks || false);
        setCurrentWc(sectionData.wc || false);
        setCurrentDropbag(sectionData.dropbag || false);
        setCurrentCrew(sectionData.crew || false);
    }, [sectionData]);

    const handleSave = () => {

        const controlPoint = {
            ...sectionData,
            name: currentName,
            food: currentFood,
            drinks: currentDrinks,
            wc: currentWc,
            dropbag: currentDropbag,
            crew: currentCrew
        } as ControlPoint;

        onSaveControlPoint(Math.floor(currentSection / 2), controlPoint);
        setEditing(false);
    }

    return (
        <>
            <Box marginY={1} paddingX={2}>
                {(sectionData.type === "control" || sectionData.type === "start" || sectionData.type === "end") && (<Box marginY={0.5}>
                    {editing ?
                        <Box display="flex" alignItems="center" gap={1} marginBottom={1}>
                            <Box marginRight={1}>
                                <TextField
                                    margin="none"
                                    required
                                    fullWidth
                                    id="controlPointName"
                                    name="controlPointName"
                                    value={currentName}
                                    autoFocus
                                    onChange={(event) => setCurrentName(event.target.value)}
                                    size="small"
                                />
                            </Box>
                            <IconButton onClick={handleSave} size="small">
                                <Check fontSize="small" />
                            </IconButton>
                        </Box> :
                        <Box display="flex" alignItems="center" gap={1} marginBottom={1}>
                            <Box>
                                <Typography variant="body2" fontWeight="bold">{
                                    currentName ? currentName :
                                        sectionData.type === "start" ? intl.formatMessage({ id: "plans.start" }) :
                                            sectionData.type === "end" ? intl.formatMessage({ id: "plans.end" }) :
                                                `${intl.formatMessage({ id: "plans.CP" })} ${Math.ceil(currentSection / 2)}`
                                }</Typography>
                            </Box>
                            <Tooltip title={intl.formatMessage({ id: "plans.editControlPointName" })}>
                                <IconButton aria-label="delete" size="small" onClick={() => setEditing(true)}>
                                    <Edit fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                    <Box textAlign="center" marginBottom={0.5}>
                        <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.servicesTitle" })}</Typography>
                    </Box>
                    {
                        editing ?
                            <Box display="flex" gap={1} flexDirection="column">
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={currentFood}
                                        onChange={(event) => setCurrentFood(event.target.checked)}
                                    />
                                }
                                    label={intl.formatMessage({ id: "data.food" })}
                                />
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={currentDrinks}
                                        onChange={(event) => setCurrentDrinks(event.target.checked)}
                                    />
                                }
                                    label={intl.formatMessage({ id: "data.drinks" })}
                                />
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={currentWc}
                                        onChange={(event) => setCurrentWc(event.target.checked)}
                                    />
                                }
                                    label={intl.formatMessage({ id: "data.wc" })}
                                />
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={currentDropbag}
                                        onChange={(event) => setCurrentDropbag(event.target.checked)}
                                    />
                                }
                                    label={intl.formatMessage({ id: "data.dropbag" })}
                                />
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={currentCrew}
                                        onChange={(event) => setCurrentCrew(event.target.checked)}
                                    />
                                }
                                    label={intl.formatMessage({ id: "data.crew" })}
                                />

                            </Box> :
                            <>
                                {currentFood && <Chip sx={{ margin: 0.5 }} label={intl.formatMessage({ id: "data.foodLabel" })} />}
                                {currentDrinks && <Chip sx={{ margin: 0.5 }} label={intl.formatMessage({ id: "data.drinksLabel" })} />}
                                {currentWc && <Chip sx={{ margin: 0.5 }} label={intl.formatMessage({ id: "data.wcLabel" })} />}
                                {currentDropbag && <Chip sx={{ margin: 0.5 }} label={intl.formatMessage({ id: "data.dropbagLabel" })} />}
                                {currentCrew && <Chip sx={{ margin: 0.5 }} label={intl.formatMessage({ id: "data.crewLabel" })} />}

                                {!currentFood && !currentDrinks && !currentDrinks && !currentDropbag && !currentCrew &&
                                    <Box textAlign="center" marginY={1}>
                                        <Typography variant="body2" color="grey" fontStyle="italic">{intl.formatMessage({ id: "data.noServices" })}</Typography>
                                    </Box>
                                }
                            </>
                    }

                </Box>)}

                {sectionData.type === "section" && <Box display="flex" alignItems="center" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.startDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                    <ArrowLine />
                    <ArrowPoint />
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.endDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                </Box>}
                {sectionData.type === "section" && <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.distanceTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.distance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                </Box>}
                {sectionData.type === "control" && <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.distanceToCPTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.startDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                </Box>}
                {!!sectionData.elevationGain && <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.elevationGainTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2" fontWeight="bold">+{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.elevationGain, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                </Box>}
                {!!sectionData.elevationLoss && <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.elevationLossTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.elevationLoss, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                </Box>}
                {sectionData.type === "section" ? <>
                    <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.minElevationTitle" })}</Typography>
                        <ParagraphLine />
                        <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.minElevation, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                    </Box>
                    <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.maxElevationTitle" })}</Typography>
                        <ParagraphLine />
                        <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.maxElevation, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                    </Box>
                </> :
                    <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.elevationTitle" })}</Typography>
                        <ParagraphLine />
                        <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.minElevation, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                    </Box>
                }

                {sectionData.type === "section" && <Tabs
                    value={sectionTableState}
                    onChange={(ev, value) => {
                        setSectionTableState(value)
                    }}
                    variant="fullWidth"
                    aria-label="tabs"
                >
                    {sectionTableState === "technicality" ? <PlannerTab icon={<NordicWalking />} iconPosition="start" value="technicality" />
                        : <Tooltip title={intl.formatMessage({ id: "plans.technicality" })} placement="top">
                            <PlannerTab icon={<NordicWalking />} iconPosition="start" value="technicality" />
                        </Tooltip>
                    }
                    {sectionTableState === "beforeAfter" ? <PlannerTab icon={<TransferWithinAStationIcon />} iconPosition="start" value="beforeAfter" />
                        : <Tooltip title={intl.formatMessage({ id: "plans.beforeAfter" })} placement="top">
                            <PlannerTab icon={<TransferWithinAStationIcon />} iconPosition="start" value="beforeAfter" />
                        </Tooltip>
                    }
                </Tabs>}

                {sectionData.type === "section" && sectionTableState === "technicality" && <Box marginY={2}>
                    <SurfaceDataBox surfaceData={surfaceData || []} sectionData={sectionData} />
                </Box>}

                {(sectionTableState === "beforeAfter" || sectionData.type !== "section") && <Box marginY={2}>
                    {sectionData.beforeDistance > 0 && (<>
                        <Box textAlign="center">
                            <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.beforeTitle" })}</Typography>
                        </Box>
                        <Box display="flex" alignItems="baseline" justifyContent="space-between">
                            <Typography variant="body2">{intl.formatMessage({ id: "data.distanceTitle" })}</Typography>
                            <ParagraphLine />
                            <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.beforeDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                        </Box>
                        <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                            <Typography variant="body2">{intl.formatMessage({ id: "data.elevationGainTitle" })}</Typography>
                            <ParagraphLine />
                            <Typography variant="body2" fontWeight="bold">+{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.beforeElevationGain, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                        </Box>
                        <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                            <Typography variant="body2">{intl.formatMessage({ id: "data.elevationLossTitle" })}</Typography>
                            <ParagraphLine />
                            <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.beforeElevationLoss, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                        </Box>
                    </>)}
                    {sectionData.afterDistance > 0 && (<>
                        <Box textAlign="center">
                            <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.afterTitle" })}</Typography>
                        </Box>
                        <Box display="flex" alignItems="baseline" justifyContent="space-between">
                            <Typography variant="body2">{intl.formatMessage({ id: "data.distanceTitle" })}</Typography>
                            <ParagraphLine />
                            <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.afterDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                        </Box>
                        <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                            <Typography variant="body2">{intl.formatMessage({ id: "data.elevationGainTitle" })}</Typography>
                            <ParagraphLine />
                            <Typography variant="body2" fontWeight="bold">+{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.afterElevationGain, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                        </Box>
                        <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                            <Typography variant="body2">{intl.formatMessage({ id: "data.elevationLossTitle" })}</Typography>
                            <ParagraphLine />
                            <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.afterElevationLoss, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                        </Box>
                    </>)}
                </Box>}


            </Box >
        </>

    );
});

export default SectionTable;